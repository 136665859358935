import React, { useEffect, useState } from 'react'
import { Map, Polygon, YMaps, ZoomControl } from 'react-yandex-maps'
import { Button, Modal } from 'react-bootstrap'
import SearchIcon from '../icons/SearchIcon'
import Client from '../json-rpc-client'
import TerritoryIcon from '../icons/TerritoryIcon'
import _ from 'lodash'

const ST = {
  LIST: 'orgList',
  SELECT: 'orgListSelected',
  EDIT: 'editTerritory',
  DRAW: 'drawTerritory',
}

const OrganizationTerritory = () => {
  const [drawRef, setDrawRef] = useState(null)
  const [drawCoords, setDrawCoords] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [state, setState] = useState({
    // sidebar
    rightSidebar: ST.LIST,
    orgList: [],
    orgListData: [],
    selectedOrg: {},
    // map
    center: [62.028103, 129.732663],
    zoom: 12,
  })
  const { center, zoom, rightSidebar, orgList, orgListData, selectedOrg } = state
  const isDrawing = rightSidebar === ST.DRAW

  useEffect(() => {
    Client('organization_getTerritoryList').then(response => {
      if (response.result)
        setState({ ...state, orgListData: response.result, orgList: response.result })
      else console.log(response.error)
    })
  }, [])

  const renderModalConfirm = () => {
    return (
      <>
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Удалить территорию</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить территорию{' '}
            <span className="font-weight-bold">{selectedOrg.name}</span>? <br />
            Действие неотвратимо.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              Отменить
            </Button>
            <Button variant="danger" onClick={deleteTerritory}>
              Удалить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  const deleteTerritory = () => {
    Client('organization_destroyTerritory', {
      id: selectedOrg.id,
    }).then(res => {
      if (res.result) {
        console.log(selectedOrg)
        selectedOrg.territory = []
        selectedOrg.territoryFound = false
        orgList[selectedOrg.id] = selectedOrg
        const rightSidebar = ST.SELECT

        drawRef.editor.stopEditing()
        setDrawCoords([])
        setShowConfirm(false)
        setState({ ...state, rightSidebar, orgList, selectedOrg, orgListData: orgList })
      } else {
        console.log(res.error)
      }
    })
  }

  const searchOrgList = e => {
    const value = e.target.value.toLowerCase()
    const orgList = _.pickBy(orgListData, org => {
      return org.name.toLowerCase().includes(value)
    })
    console.log('pickBy', orgList)
    setState({ ...state, orgList })
  }
  const selectOrg = selectedOrg => {
    console.log(drawCoords)
    setState(prevState => {
      let rightSidebar = ST.SELECT
      let select = true
      const orgList = _.mapValues(prevState.orgList, org => {
        if (org.id === selectedOrg.id && org.cls === 'active') {
          select = false
          rightSidebar = ST.LIST
          org.cls = ''
        } else if (org.id === selectedOrg.id) {
          org.cls = 'active'
        } else {
          org.cls = ''
        }
        return org
      })
      if (select) {
        setDrawCoords(selectedOrg.territory)
      } else {
        setDrawCoords([])
      }

      return { ...state, rightSidebar, orgList, selectedOrg }
    })
  }

  const startDrawing = () => {
    console.log('startDrawing')
    setState({ ...state, rightSidebar: ST.DRAW })
    drawRef.editor.startDrawing()
  }

  const drawPreparation = ref => {
    if (!drawRef) {
      ref.editor.events.add('drawingstop', event => {
        const drawCoords = [event.get('target').geometry._coordPath._coordinates[0]]
        setDrawCoords(drawCoords)
      })
      ref.editor.events.add('vertexdragend', event => {
        const drawCoords = [event.get('target').geometry._coordPath._coordinates[0]]
        setDrawCoords(drawCoords)
      })
      ref.editor.events.add('vertexadd', event => {
        const drawCoords = [event.get('target').geometry._coordPath._coordinates[0]]
        setDrawCoords(drawCoords)
      })
      setDrawRef(ref)
    }
  }

  const renderDrawPolygon = () => {
    return (
      <Polygon
        instanceRef={ref => ref && drawPreparation(ref)}
        geometry={drawCoords}
        options={{
          editorDrawingCursor: 'crosshair',
          fillColor: '#fc3627',
          strokeColor: '#fc3627',
          strokeOpacity: 0.6,
          fillOpacity: 0.1,
          strokeWidth: 3,
          visible: true,
          geodesic: true,
        }}
      />
    )
  }

  const renderPolygonList = () => {
    if (rightSidebar !== ST.LIST) return true
    let territoryList = _.filter(orgList, org => !_.isEmpty(org.territory))
    territoryList = _.map(territoryList, org => {
      return {
        orgName: org.name,
        geometry: org.territory,
      }
    })
    console.log(territoryList)
    return _.map(territoryList, (terr, i) => {
      return (
        <Polygon
          key={i}
          geometry={terr.geometry}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          options={{
            editorDrawingCursor: 'crosshair',
            fillColor: '#fc3627',
            strokeColor: '#fc3627',
            strokeOpacity: 0.6,
            fillOpacity: 0.1,
            strokeWidth: 3,
            visible: true,
            geodesic: true,
          }}
          properties={{
            balloonContentHeader: terr.orgName,
            balloonContentBody: terr.orgName,
            hintContent: terr.orgName,
          }}
        />
      )
    })
  }

  const renderHeaderButton = () => {
    switch (rightSidebar) {
      case ST.SELECT:
        return (
          <>
            <Button
              variant="outline-primary"
              style={{ width: '160px', marginLeft: '20px' }}
              onClick={startDrawing}
            >
              <TerritoryIcon />
              <span style={{ marginLeft: '5px' }}>Редактировать</span>
            </Button>
          </>
        )
      case ST.DRAW:
        return (
          <>
            <>
              <Button
                variant="outline-primary"
                style={{ width: '140px', marginLeft: '20px' }}
                onClick={cancelEdit}
              >
                <TerritoryIcon />
                <span style={{ marginLeft: '5px' }}>Отменить</span>
              </Button>
            </>
            <Button
              onClick={updateTerritory}
              style={{ width: '140px', marginLeft: '20px' }}
              variant="outline-primary"
            >
              Сохранить
            </Button>
            <Button
              onClick={() => setShowConfirm(true)}
              style={{ width: '140px', marginLeft: '20px' }}
              variant="outline-danger"
            >
              Удалить
            </Button>
          </>
        )
      default:
        return true
    }
  }

  const cancelEdit = () => {
    drawRef.editor.stopEditing()
    setDrawCoords(selectedOrg.territory)
    setState({ ...state, rightSidebar: ST.SELECT })
  }

  const onLoadMap = ymaps => {
    console.log('onLoadMap')
  }

  const updateTerritory = () => {
    drawRef.editor.stopEditing()
    Client('organization_updateTerritory', {
      id: selectedOrg.id,
      coordinates: drawCoords,
    }).then(response => {
      if (response.result) {
        console.log(selectedOrg)
        selectedOrg.territory = drawCoords
        selectedOrg.territoryFound = true
        orgList[selectedOrg.id] = selectedOrg
        const rightSidebar = ST.SELECT
        setDrawCoords(selectedOrg.territory)
        setState({ ...state, rightSidebar, orgList, selectedOrg, orgListData: orgList })
      } else console.log(response.error)
    })
  }

  const renderMap = () => {
    return (
      <YMaps>
        <Map
          lang="ru-RU"
          modules={['util.bounds', 'borders', 'ObjectManager', 'geoObject.addon.editor']}
          className="territory-map"
          state={{ center, zoom }}
          onLoad={onLoadMap}
        >
          <ZoomControl />
          {renderPolygonList()}
          {renderDrawPolygon()}
        </Map>
      </YMaps>
    )
  }

  const renderRightSidebar = () => {
    return (
      <>
        <div className="header-bar">
          <input onChange={e => searchOrgList(e)} placeholder="Поиск медучреждения" />
          <div className="icon">
            <SearchIcon />
          </div>
        </div>
        <div className="org-list">
          {_.map(orgList, (org, i) => {
            const info = org.territoryFound ? 'Территория добавлена' : '-'
            return (
              <div key={i} className={`org ${org.cls}`} onClick={() => selectOrg(org)}>
                <div className="name">{org.name}</div>
                <div className="territories-count">{info}</div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Территории организаций</span>
          {renderHeaderButton()}
        </div>
        <div className="territory-container">
          {renderMap()}
          <div className="territory-orgs">{renderRightSidebar()}</div>
        </div>
      </div>
      {renderModalConfirm()}
    </>
  )
}

export default OrganizationTerritory

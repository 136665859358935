const TerritoryIcon = () => {
  return (
    <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3169 2.05806C15.561 2.30214 15.561 2.69786 15.3169 2.94194L11.8794 6.37944C11.6354 6.62352 11.2396 6.62352 10.9956 6.37944C10.7515 6.13536 10.7515 5.73964 10.9956 5.49556L14.4331 2.05806C14.6771 1.81398 15.0729 1.81398 15.3169 2.05806ZM5.62944 2.99556C5.87352 3.23964 5.87352 3.63536 5.62944 3.87944L1.56694 7.94194C1.32286 8.18602 0.927136 8.18602 0.683058 7.94194C0.438981 7.69786 0.438981 7.30214 0.683058 7.05806L4.74556 2.99556C4.98964 2.75148 5.38536 2.75148 5.62944 2.99556ZM6.62056 2.99556C6.86464 2.75148 7.26036 2.75148 7.50444 2.99556L10.0044 5.49556C10.2485 5.73964 10.2485 6.13536 10.0044 6.37944C9.76036 6.62352 9.36464 6.62352 9.12056 6.37944L6.62056 3.87944C6.37648 3.63536 6.37648 3.23964 6.62056 2.99556Z"
        fill="#25BBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8125 1.25C15.6399 1.25 15.5 1.38991 15.5 1.5625C15.5 1.73509 15.6399 1.875 15.8125 1.875C15.9851 1.875 16.125 1.73509 16.125 1.5625C16.125 1.38991 15.9851 1.25 15.8125 1.25ZM14.25 1.5625C14.25 0.699555 14.9496 0 15.8125 0C16.6754 0 17.375 0.699555 17.375 1.5625C17.375 2.42544 16.6754 3.125 15.8125 3.125C14.9496 3.125 14.25 2.42544 14.25 1.5625Z"
        fill="#25BBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.5625C10.3274 6.5625 10.1875 6.70241 10.1875 6.875C10.1875 7.04759 10.3274 7.1875 10.5 7.1875C10.6726 7.1875 10.8125 7.04759 10.8125 6.875C10.8125 6.70241 10.6726 6.5625 10.5 6.5625ZM8.9375 6.875C8.9375 6.01206 9.63706 5.3125 10.5 5.3125C11.3629 5.3125 12.0625 6.01206 12.0625 6.875C12.0625 7.73794 11.3629 8.4375 10.5 8.4375C9.63706 8.4375 8.9375 7.73794 8.9375 6.875Z"
        fill="#25BBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.125 2.1875C5.95241 2.1875 5.8125 2.32741 5.8125 2.5C5.8125 2.67259 5.95241 2.8125 6.125 2.8125C6.29759 2.8125 6.4375 2.67259 6.4375 2.5C6.4375 2.32741 6.29759 2.1875 6.125 2.1875ZM4.5625 2.5C4.5625 1.63706 5.26206 0.9375 6.125 0.9375C6.98794 0.9375 7.6875 1.63706 7.6875 2.5C7.6875 3.36294 6.98794 4.0625 6.125 4.0625C5.26206 4.0625 4.5625 3.36294 4.5625 2.5Z"
        fill="#25BBBB"
      />
    </svg>
  )
}

export default TerritoryIcon

const HospitalIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9999 12.4037H25.6668V21.5821H14.9999V12.4037Z" fill="#B2D7F3" />
      <path d="M4.33313 12.4037H15V21.5821H4.33313V12.4037Z" fill="#D6E9F8" />
      <path d="M21.6458 13.6648H22.7351V14.7541H21.6458V13.6648Z" fill="#613393" />
      <path d="M21.6458 15.6943H22.7351V16.7836H21.6458V15.6943Z" fill="#613393" />
      <path d="M23.6832 13.6648H24.7724V14.7541H23.6832V13.6648Z" fill="#613393" />
      <path d="M23.6832 15.6943H24.7724V16.7836H23.6832V15.6943Z" fill="#613393" />
      <path d="M21.6458 17.7238H22.7351V18.8131H21.6458V17.7238Z" fill="#613393" />
      <path d="M21.6458 19.7533H22.7351V20.8426H21.6458V19.7533Z" fill="#613393" />
      <path d="M23.6832 17.7238H24.7724V18.8131H23.6832V17.7238Z" fill="#613393" />
      <path d="M23.6832 19.7533H24.7724V20.8426H23.6832V19.7533Z" fill="#613393" />
      <path d="M5.23969 13.6648H6.32894V14.7541H5.23969V13.6648Z" fill="#7A3EB4" />
      <path d="M5.23969 15.6943H6.32894V16.7836H5.23969V15.6943Z" fill="#7A3EB4" />
      <path d="M7.27704 13.6648H8.3663V14.7541H7.27704V13.6648Z" fill="#7A3EB4" />
      <path d="M7.27704 15.6943H8.3663V16.7836H7.27704V15.6943Z" fill="#7A3EB4" />
      <path d="M5.23969 17.7238H6.32894V18.813H5.23969V17.7238Z" fill="#7A3EB4" />
      <path d="M5.23969 19.7533H6.32894V20.8426H5.23969V19.7533Z" fill="#7A3EB4" />
      <path d="M7.27704 17.7238H8.3663V18.813H7.27704V17.7238Z" fill="#7A3EB4" />
      <path d="M7.27704 19.7533H8.3663V20.8426H7.27704V19.7533Z" fill="#7A3EB4" />
      <path d="M20.9512 11.9365H25.9788V12.8709H20.9512V11.9365Z" fill="#613393" />
      <path d="M4.02118 11.9365H9.04876V12.8709H4.02118V11.9365Z" fill="#7A3EB4" />
      <path
        d="M20.9513 22.1681H12.4112L14.9999 21.5821L17.5889 22.1681H9.04877V8.04369H20.9513V22.1681Z"
        fill="#EEF4FF"
      />
      <path d="M20.9513 22.1681H17.5888L15 21.5821V8.04369H20.9513V22.1681Z" fill="#D6E9F8" />
      <path d="M12.4111 17.6942H17.5888V22.1681H12.4111V17.6942Z" fill="#613393" />
      <path d="M8.51257 7.6684H21.4875V8.60273H8.51257V7.6684Z" fill="#7A3EB4" />
      <path d="M12.4111 17.6942H15V22.1681H12.4111V17.6942Z" fill="#7A3EB4" />
      <path d="M18.7208 15.6943H19.8101V16.7836H18.7208V15.6943Z" fill="#613393" />
      <path d="M18.7208 17.7238H19.8101V18.813H18.7208V17.7238Z" fill="#613393" />
      <path d="M18.7208 19.7533H19.8101V20.8426H18.7208V19.7533Z" fill="#613393" />
      <path d="M10.1198 15.6943H11.2091V16.7836H10.1198V15.6943Z" fill="#7A3EB4" />
      <path d="M10.1198 17.7238H11.2091V18.813H10.1198V17.7238Z" fill="#7A3EB4" />
      <path d="M10.1198 19.7533H11.2091V20.8426H10.1198V19.7533Z" fill="#7A3EB4" />
      <path d="M12.4111 11.3145H17.5888V12.4037H12.4111V11.3145Z" fill="#EDBE00" />
      <path d="M14.9999 11.3145H17.5888V12.4037H14.9999V11.3145Z" fill="#FF9E22" />
      <path d="M14.9999 7.6684H21.4874V8.60273H14.9999V7.6684Z" fill="#613393" />
      <path
        d="M15 10.7831C16.4622 10.7831 17.6476 9.59777 17.6476 8.13557C17.6476 6.67336 16.4622 5.48801 15 5.48801C13.5378 5.48801 12.3525 6.67336 12.3525 8.13557C12.3525 9.59777 13.5378 10.7831 15 10.7831Z"
        fill="#EEF4FF"
      />
      <path
        d="M17.6476 8.13557C17.6476 6.67336 16.4622 5.48801 15 5.48801V10.7831C16.4622 10.7831 17.6476 9.59777 17.6476 8.13557Z"
        fill="#D6E9F8"
      />
      <path
        d="M16.5777 7.70813H15.4274V6.55775H14.5726V7.70813H13.4222V8.56301H14.5726V9.71338H15.4274V8.56301H16.5777V7.70813Z"
        fill="#FF6379"
      />
      <path
        d="M15.4274 6.55775H15V9.71338H15.4274V8.56301H16.5778V7.70813H15.4274V6.55775Z"
        fill="#FF1F3E"
      />
      <path d="M12.383 15.6943H13.4723V16.7836H12.383V15.6943Z" fill="#7A3EB4" />
      <path d="M14.4203 15.6943H15.5096V16.7836H14.4203V15.6943Z" fill="#7A3EB4" />
      <path d="M16.5706 15.6943H17.6598V16.7836H16.5706V15.6943Z" fill="#613393" />
      <path d="M18.7208 13.6648H19.8101V14.7541H18.7208V13.6648Z" fill="#613393" />
      <path d="M10.1198 13.6648H11.2091V14.7541H10.1198V13.6648Z" fill="#7A3EB4" />
      <path d="M12.383 13.6648H13.4723V14.7541H12.383V13.6648Z" fill="#7A3EB4" />
      <path d="M14.4203 13.6648H15.5096V14.7541H14.4203V13.6648Z" fill="#7A3EB4" />
      <path d="M16.5706 13.6648H17.6598V14.7541H16.5706V13.6648Z" fill="#613393" />
      <path d="M14.965 15.6943H15.5096V16.7836H14.965V15.6943Z" fill="#613393" />
      <path d="M14.965 13.6648H15.5096V14.7541H14.965V13.6648Z" fill="#613393" />
    </svg>
  )
}

export default HospitalIcon

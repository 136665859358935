import React, { Component } from 'react'
import { Container, Row, Col, Button, Table, Modal, InputGroup, FormControl } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import Select from 'react-select'
import { YMaps, Map, ZoomControl, Placemark, Polygon, GeoObject, SearchControl } from 'react-yandex-maps'
import EditIcon from '../icons/EditIcon'
const { SearchBar } = Search

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
  <div className="btn-group" role="group">
    {options.map(option => {
      const isSelect = currSizePerPage === `${option.page}`
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? 'btn-primary' : 'page-link'}`}
        >
          {option.text}
        </button>
      )
    })}
  </div>
)

class Organizations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'id',
          text: 'ID',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'name',
          text: 'Мед учреждение',
          headerStyle: { minWidth: '400px' },
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'type',
          text: 'Тип учреждения',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'district',
          text: 'Улус (Район)',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'settlement',
          text: 'Населенный пункт',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'address',
          text: 'Адрес',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'contacts',
          text: 'Контакты',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'connection',
          text: 'Тип связи',
          sort: true,
          sortCaret: (order, column) => this.sortCaret(order),
        },
        {
          dataField: 'df1',
          isDummyField: true,
          text: '',
          style: { textAlign: 'right' },
          formatter: (cellContent, row) => {
            return (
              <span className="edit-icon" onClick={this.changeTableHospitals.bind(this, row)}>
                <EditIcon />
              </span>
            )
          },
        },
      ],

      defaultSorted: [
        {
          dataField: 'id',
          order: 'asc',
        },
      ],

      hospitals: [],
      selectInternets: [
        { value: 'xdsl', label: 'xDSL' },
        { value: 'fttb', label: 'FTTB' },
        { value: 'xpon', label: 'xPON' },
        { value: 'gprs', label: 'GPRS' },
        { value: 'satellite', label: 'Спутниковая связь' },
        { value: '3g', label: '3G' },
        { value: 'lte', label: 'LTE' },
        { value: 'no', label: 'Нет' },
      ],
      valueInternets: '',
      modalShow: false,
      modalMode: '',

      changeRowId: '',
      valueName: '',
      valueType: '',
      valueSettlement: '',
      valueDistrict: '',
      valueConnection: '',
      valueStreet: '',
      valueHouse: '',
      valueAddress: '',
      valueContacts: '',
      valueLatitude: '',
      valueLongitude: '',
      valueCoordinates: '',
      valueSearch: '',

      errorName: '',
      errorType: '',
      errorSettlement: '',
      errorDistrict: '',
      errorConnection: '',
      errorAddress: '',
      errorContacts: '',
      errorCoordinates: '',

      valueDistrictStyle: {
        option: styles => ({ ...styles, zIndex: 3 }),
      },

      mapCenter: [62.028103, 129.732663],
      zoom: 16,
      coordinates: [],
      foundedArr: [],
      valueOptionsAddress: '',
      optionsSettlements: [],
      optionsDistricts: [],
      latitude: 0,
      longitude: 0,
    }
  }

  sortCaret = order => {
    if (!order) return <span className="order-4" />
    else if (order === 'asc') return <span className="caret-4-asc" />
    else if (order === 'desc') return <span className="caret-4-desc" />
    return null
  }

  fetchHospitals = () => {
    fetch(`${process.env.REACT_APP_URL}/api/orgs`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          hospitals: result,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  fetchAddData = () => {
    fetch(`${process.env.REACT_APP_URL}/api/org/store`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        name: this.state.valueName,
        type_id: this.state.valueType.value,
        district_id: this.state.valueDistrict.value,
        settlement_id: this.state.valueSettlement.value,
        // 'district': this.state.valueDistrict,
        // 'settlement': this.state.valueSettlement,
        connection_id: this.state.valueConnection.value,
        contacts: this.state.valueContacts,
        street: this.state.valueStreet,
        house: this.state.valueHouse,
        address: this.state.valueAddress,
        latitude: this.state.valueLatitude,
        longitude: this.state.valueLongitude,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          this.setState({
            modalShow: false,
            errorName: '',
            errorType: '',
            errorConnection: '',
            errorDistrict: '',
            errorSettlement: '',
            errorAddress: '',
            errorContacts: '',
            errorCoordinates: '',
          })
          alert('Новое учреждение успешно добавлено')
          this.fetchHospitals()
        } else {
          console.log(response)
          alert('Произошла ошибка')
          let errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates
          errorName = response.errors.name ? response.errors.name[0] : ''
          errorType = response.errors.type_id ? response.errors.type_id[0] : ''
          errorConnection = response.errors.connection_id ? response.errors.connection_id[0] : ''
          errorDistrict = response.errors.district_id ? response.errors.district_id[0] : ''
          errorSettlement = response.errors.settlement_id ? response.errors.settlement_id[0] : ''
          errorAddress = response.errors.address ? response.errors.address[0] : ''
          errorContacts = response.errors.contacts ? response.errors.contacts[0] : ''
          errorCoordinates = response.errors.longitude ? response.errors.longitude[0] : ''

          console.log(
            errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates,
          )
          this.setState({
            errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates,
          })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        console.log(e)
      })
  }

  fetchChangeData = () => {
    fetch(`${process.env.REACT_APP_URL}/api/org/${this.state.changeRowId}/update`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        name: this.state.valueName,
        type_id: this.state.valueType.value,
        district_id: this.state.valueDistrict.value,
        settlement_id: this.state.valueSettlement.value,
        // 'district': this.state.valueDistrict,
        // 'settlement': this.state.valueSettlement,
        connection_id: this.state.valueConnection.value,
        address: this.state.valueAddress,
        contacts: this.state.valueContacts,
        latitude: this.state.valueLatitude,
        longitude: this.state.valueLongitude,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          this.setState({
            modalShow: false,
            errorName: '',
            errorType: '',
            errorConnection: '',
            errorDistrict: '',
            errorSettlement: '',
            errorAddress: '',
            errorContacts: '',
            errorCoordinates: '',
          })
          this.fetchHospitals()
          alert('Изменения успешно внесены')
        } else {
          alert('Произошла ошибка')
          console.log(response.errors)
          let errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates
          errorName = response.errors.name ? response.errors.name[0] : ''
          errorType = response.errors.type_id ? response.errors.type_id[0] : ''
          errorConnection = response.errors.connection_id ? response.errors.connection_id[0] : ''
          errorDistrict = response.errors.district_id ? response.errors.district_id[0] : ''
          errorSettlement = response.errors.settlement_id ? response.errors.settlement_id[0] : ''
          errorAddress = response.errors.address ? response.errors.address[0] : ''
          errorContacts = response.errors.contacts ? response.errors.contacts[0] : ''
          errorCoordinates = response.errors.longitude ? response.errors.longitude[0] : ''

          console.log(
            errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates,
          )
          this.setState({
            errorName,
            errorType,
            errorConnection,
            errorDistrict,
            errorSettlement,
            errorAddress,
            errorContacts,
            errorCoordinates,
          })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        console.log(e)
      })
  }

  foundArrLogic = data => {
    let arr = []
    data.map(l => {
      let address = l.GeoObject.metaDataProperty.GeocoderMetaData.Address
      arr.push({
        value: address.formatted,
        label: address.formatted,
        coordinate: l.GeoObject.Point.pos,
      })
    })
    this.setState({
      foundedArr: arr,
      valueOptionsAddress: { value: 1, label: this.state.valueAddress },
      valueAddress: '',
    })
    this.select.onMenuOpen()
  }

  onSearch = () => {
    alert('анастас должен сделать')
  }

  componentDidMount() {
    this.setState({
      hospitals: this.props.data,
      optionsSettlements: this.props.optionsSettlements,
      optionsDistricts: this.props.optionsDistricts,
    })
  }

  changeKindInternet = data => {
    this.setState({
      valueInternets: data,
    })
  }

  changeTableHospitals = data => {
    console.log(data)
    this.setModalShow(true)
    this.setState({
      changeRowId: data.id,
      valueName: data.name,
      valueType: {
        label: data.type,
        value: data.type_id,
      },
      valueDistrict: {
        label: data.district,
        value: data.district_id,
      },
      valueSettlement: {
        label: data.settlement,
        value: data.settlement_id,
      },
      optionsSettlements: this.filterDataInArray(this.props.optionsSettlements, 'district_id', data.district_id),
      valueConnection: {
        label: data.connection,
        value: data.connection_id,
      },
      valueAddress: data.address,
      valueContacts: data.contacts,
      mapCenter: [data.latitude, data.longitude],
      coordinates: [data.latitude, data.longitude],
      valueLatitude: data.latitude,
      valueLongitude: data.longitude,
      valueCoordinates: `${data.latitude}, ${data.longitude}`,
      zoom: 16,
      modalMode: 'edit',
    })
  }

  addTableInternets = () => {
    this.setModalShow(true)
    this.setState({
      modalMode: 'add',
      changeRowId: '',

      valueName: '',
      valueType: '',
      valueDistrict: '',
      valueSettlement: '',
      valueConnection: '',
      valueAddress: '',
      valueContacts: '',

      mapCenter: [62.028103, 129.732663],
      zoom: 16,
      valueCoordinates: '',
      coordinates: '',
      valueLatitude: '',
      valueLongitude: '',

      optionsSettlements: this.props.optionsSettlements,
      optionsDistricts: this.props.optionsDistricts,

      errorName: '',
      errorType: '',
      errorConnection: '',
      errorDistrict: '',
      errorSettlement: '',
      errorAddress: '',
      errorContacts: '',
      errorCoordinates: '',
    })
  }

  setModalShow = data => {
    this.setState({
      modalShow: data,
    })
  }

  saveChangeTable = () => {
    if (this.state.modalMode == 'add') {
      this.fetchAddData()
    } else {
      this.fetchChangeData()
    }
  }

  deleteTableRow = () => {
    fetch(`${process.env.REACT_APP_URL}/api/org/${this.state.changeRowId}/destroy`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        alert('Учреждение успешно удалено')
        this.setModalShow(false)
        this.componentDidMount()
        this.fetchHospitals()
      })
      .catch(e => {
        alert('Произошла ошибка')
        this.setModalShow(false)
        console.log(e)
      })
  }

  changeAdress = data => {
    this.setState({
      valueOptionsAddress: data,
    })
    let arrCoordinate = data.coordinate.split(' ').reverse()
    this.mapRef.setCenter(arrCoordinate, 16)
  }

  selectDistricts = data => {
    let cities = this.filterDataInArray(this.props.optionsSettlements, 'district_id', data.id)
    this.setState({
      valueDistrict: data,
      valueSettlement: '',
      optionsSettlements: cities,
      mapCenter: data.coordinates,
      zoom: data.zoom,
    })
  }

  selectCities = data => {
    let district = this.findDataInArray(this.props.optionsDistricts, 'id', data.district_id)
    console.log(data)
    this.setState({
      valueSettlement: data,
      valueDistrict: district,
      mapCenter: data.coordinates,
      zoom: data.zoom,
    })
  }

  filterDataInArray = (arr, key, id) => {
    let data = arr.filter(data => {
      return data[key] == id
    })
    return data
  }

  findDataInArray = (arr, key, id) => {
    let data = arr.find(x => x[key] == id)
    return data
  }

  MyVerticallyCenteredModal() {
    console.log(this.state.foundedArr, 123)
    return (
      <Modal
        show={this.state.modalShow}
        onHide={() => this.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.modalMode == 'edit' ? 'Редактировать' : 'Добавить'} учреждение
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Row>
                <Col>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Название учреждения"
                      value={this.state.valueName}
                      onChange={data => this.setState({ valueName: data.target.value })}
                    />
                    <div className="invalid-feedback" style={{ display: this.state.errorName ? 'block' : 'none' }}>
                      {this.state.errorName}
                    </div>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Select
                      onChange={(data, type) => this.setState({ valueType: data })}
                      value={this.state.valueType}
                      placeholder="Тип учреждения"
                      options={this.props.optionsTypes}
                    />
                    <div className="invalid-feedback" style={{ display: this.state.errorType ? 'block' : 'none' }}>
                      {this.state.errorType}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Select
                      onChange={(data, type) => this.setState({ valueConnection: data })}
                      value={this.state.valueConnection}
                      placeholder="Тип связи"
                      options={this.props.optionsConnections}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ display: this.state.errorConnection ? 'block' : 'none' }}
                    >
                      {this.state.errorConnection}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <FormControl
                  as="textarea"
                  placeholder="Описание и контактные данные"
                  value={this.state.valueContacts}
                  className={this.state.errorContacts ? 'is-invalid' : ''}
                  onChange={data => this.setState({ valueContacts: data.target.value })}
                />
                <div className="invalid-feedback" style={{ display: this.state.errorContacts ? 'block' : 'none' }}>
                  {this.state.errorContacts}
                </div>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Select
                onChange={(data, type) => this.selectDistricts(data)}
                value={this.state.valueDistrict}
                styles={this.state.valueDistrictStyle}
                placeholder="Улус (Район)"
                options={this.state.optionsDistricts}
              />
              <div className="invalid-feedback" style={{ display: this.state.errorDistrict ? 'block' : 'none' }}>
                {this.state.errorDistrict}
              </div>
            </Col>
            <Col className="mb-3">
              <Select
                onChange={(data, type) => this.selectCities(data)}
                value={this.state.valueSettlement}
                placeholder="Населенный пункт"
                options={this.state.optionsSettlements}
              />
              <div className="invalid-feedback" style={{ display: this.state.errorSettlement ? 'block' : 'none' }}>
                {this.state.errorSettlement}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              title="Выберите населенный пункт"
              style={{
                height: '400px',
                padding: '0',
                marginLeft: '15px',
                marginRight: '15px',
                marginBottom: '15px',
                cursor: this.state.valueSettlement ? 'unset' : 'not-allowed',
              }}
            >
              <div style={{ pointerEvents: this.state.valueSettlement ? 'unset' : 'none' }}>
                <YMaps
                  enterprise
                  query={{
                    apikey: process.env.REACT_APP_APIKEY,
                  }}
                >
                  <Map
                    instanceRef={ref => (this.mapRef = ref)}
                    style={{ width: '100%', height: '400px' }}
                    state={{
                      center: this.state.mapCenter,
                      zoom: this.state.zoom,
                    }}
                  >
                    <SearchControl
                      onClear={this.onClear}
                      onResultShow={this.onResultShow}
                      onResultSelect={this.onResultSelect}
                      options={{
                        provider: 'yandex#map',
                        maxWidth: 660,
                        fitMaxWidth: true,
                        boundedBy: [
                          [54.6414, 101.564],
                          [77.2557, 161.454],
                        ],
                        strictBounds: true,
                      }}
                      instanceRef={ref => (this.searchRef = ref)}
                      // Используем коллбэк функцию при загрузке карты
                      onLoad={ymaps => this.searchStart(ymaps)}
                    />
                    <Placemark geometry={this.state.coordinates} />
                  </Map>
                </YMaps>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <FormControl placeholder="Адрес" value={this.state.valueAddress} disabled />
                <div className="invalid-feedback" style={{ display: this.state.errorAddress ? 'block' : 'none' }}>
                  {this.state.errorAddress}
                </div>
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <FormControl placeholder="Координаты" value={this.state.valueCoordinates} disabled />
                <div className="invalid-feedback" style={{ display: this.state.errorCoordinates ? 'block' : 'none' }}>
                  {this.state.errorCoordinates}
                </div>
              </InputGroup>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.state.modalMode == 'edit' ? (
            <Button onClick={this.deleteTableRow} variant="danger">
              Удалить
            </Button>
          ) : null}
          <Button variant="outline-primary" onClick={this.saveChangeTable}>
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  onClear = data => {
    console.log('onClear')
    this.setState({
      valueDistrict: '',
      valueSettlement: '',
      valueAddress: '',
      valueLatitude: 0,
      valueLongitude: 0,
      valueCoordinates: [],
    })
  }

  onResultSelect = data => {
    console.log('onResultSelect')
    let fullAddress = data.originalEvent.target._yandexState._model.request
    let coordinates = data.originalEvent.target.state._data.results[0].geometry._coordinates
    let addressComponent =
      data.originalEvent.target.state._data.results[0].properties._data.metaDataProperty.GeocoderMetaData.Address

    console.log(addressComponent.Components)
    // console.log(fullAddress)
    // console.log(coordinates)

    let district, settlement, address, street, house
    addressComponent.Components.forEach(item => {
      if (item.kind === 'area') district = item.name || ''
      if (item.kind === 'street') street = item.name || ''
      if (item.kind === 'house') house = item.name || ''
    })
    addressComponent.Components.reverse().forEach(item => {
      if (item.kind === 'locality') settlement = item.name || ''
    })
    if (street && house) {
      address = street + ', ' + house
    } else if (street) {
      address = street
    } else {
      address = house
    }

    console.log('Район:', district)
    console.log('Населенный пункт:', settlement)
    console.log('Адрес:', address)

    this.setState({
      mapCenter: coordinates,
      // valueDistrict: district,
      // valueSettlement: settlement,
      valueStreet: street,
      valueHouse: house,
      valueAddress: address,
      valueLatitude: coordinates[0],
      valueLongitude: coordinates[1],
      valueCoordinates: coordinates.join(', '),
    })
  }

  onResultShow = data => {
    console.log('onResultShow')
  }

  searchStart = data => {
    // this.setState({mapCenter: [62.028103, 129.732663]})
    // if (!data.get('skip') && this.searchRef.getResultsCount()) {
    //     console.log(this.searchRef.showResult(0))
    // }
  }

  render() {
    return (
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Список медицинских учреждений</span>
          <Button
            style={{ width: '140px', marginLeft: '20px' }}
            onClick={this.addTableInternets}
            variant="outline-primary"
            block
          >
            Добавить
          </Button>
        </div>
        <ToolkitProvider keyField="id" data={this.state.hospitals} columns={this.state.columns} search>
          {props => (
            <React.Fragment>
              <div className="admin-table-search">
                <SearchBar placeholder="Поиск" {...props.searchProps} />
              </div>
              <BootstrapTable
                bordered={false}
                headerWrapperClasses="table-header"
                bodyClasses="table-body"
                rowClasses="table-row"
                classes="admin-table"
                bootstrap4
                {...props.baseProps}
                pagination={paginationFactory({ sizePerPageRenderer })}
                defaultSorted={this.state.defaultSorted}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
        {this.MyVerticallyCenteredModal()}
      </div>
    )
  }
}

export default Organizations

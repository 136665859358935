const Territory2Icon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9512 10.1722C25.9309 10.0599 25.8612 9.96256 25.7614 9.90706L25.3566 9.68195L20.6935 10.7218L27.4808 18.5991L25.9512 10.1722Z"
        fill="#B2D7F3"
      />
      <path
        d="M15 21.8752C15 21.8752 15 21.8752 15 21.8752V21.4939L9.15902 12.6235L8.65948 19.0674L14.8437 21.8418C14.8936 21.8642 14.9469 21.8752 15 21.8752Z"
        fill="#B2D7F3"
      />
      <path
        d="M27.9939 21.4259L25.9513 10.1722C25.9309 10.0599 25.8613 9.96257 25.7614 9.90706L20.6202 7.04756C20.5535 7.01039 20.4787 6.99561 20.4052 7.0013L21.3406 19.0674L21.3092 19.0814L27.4621 21.8417C27.5122 21.8641 27.5654 21.8752 27.6183 21.8752C27.7022 21.8752 27.7853 21.8476 27.8536 21.7941C27.9652 21.707 28.0192 21.5652 27.9939 21.4259Z"
        fill="#71C4B8"
      />
      <path
        d="M15.0001 9.78122L9.74191 7.04253C9.67889 7.00967 9.60901 6.99626 9.54021 7.00089C9.55844 6.99977 9.57677 6.99987 9.59495 7.00129L8.65961 19.0673L14.8438 21.8417C14.8937 21.8641 14.947 21.8752 15.0001 21.8752V9.78122V9.78122Z"
        fill="#71C4B8"
      />
      <path
        d="M9.59485 7.00135C9.52137 6.99566 9.44657 7.01044 9.37985 7.04756L4.23859 9.90706C4.13875 9.96256 4.06908 10.0598 4.04872 10.1722L2.00614 21.4259C1.98085 21.5651 2.03488 21.7069 2.14645 21.7942C2.21485 21.8476 2.29788 21.8752 2.38167 21.8752C2.43463 21.8752 2.4879 21.8642 2.53792 21.8417L8.65728 19.0965L9.59485 7.00135Z"
        fill="#51CAB9"
      />
      <path
        d="M20.4052 7.0011C20.3546 7.00501 20.3046 7.01847 20.2582 7.04264L15.0001 9.78128V21.8753C15.0001 21.8753 15.0001 21.8753 15.0001 21.8753C15.0532 21.8753 15.1065 21.8642 15.1564 21.8418L21.3406 19.0674L20.4052 7.0011Z"
        fill="#51CAB9"
      />
      <path
        d="M3.42971 13.5826L3.01477 15.8687L9.15901 12.6235L9.30166 10.7832L9.32532 10.478L3.42971 13.5826Z"
        fill="#FFDB56"
      />
      <path
        d="M9.2143 11.9099L9.159 12.6235L3.01476 15.8688L2.00614 21.4259C1.98085 21.5652 2.03488 21.7069 2.14645 21.7942C2.21485 21.8477 2.29788 21.8752 2.38167 21.8752C2.43463 21.8752 2.4879 21.8642 2.53792 21.8418L8.65728 19.0965L9.2143 11.9099Z"
        fill="#D6E9F8"
      />
      <path
        d="M15.0016 11.2897L9.32531 10.4781L9.159 12.6235L15 21.494V18.2684L11.5145 12.7801L15.0016 13.1971V11.2897Z"
        fill="#FFBB24"
      />
      <path
        d="M25.3567 9.68201L22.887 8.30832L20.5489 8.85463L20.5781 9.23223L20.6936 10.7219L25.3567 9.68201Z"
        fill="#FFBB24"
      />
      <path
        d="M20.9009 13.3969L20.6795 10.5405L20.5488 8.85463L15.0015 11.2897V13.1971L18.6251 11.6214L20.9009 13.3969Z"
        fill="#FFDB56"
      />
      <path
        d="M27.9939 21.4259L27.4809 18.5991L20.6936 10.7218L20.901 13.3969L27.8616 21.7871C27.9674 21.6994 28.0186 21.5616 27.9939 21.4259Z"
        fill="#FFBB24"
      />
      <path
        d="M15.1563 21.8417L17.4559 20.8101L15 18.2684V21.8752C15 21.8752 15 21.8752 15.0001 21.8752C15.0531 21.8752 15.1064 21.8642 15.1563 21.8417Z"
        fill="#FFDB56"
      />
    </svg>
  )
}

export default Territory2Icon

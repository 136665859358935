import axios from 'axios'

export const AxiosClient = (method, params = []) => {
  return axios.post(
    `${process.env.REACT_APP_URL}/back/jsonrpc`,
    {
      jsonrpc: '2.0',
      method: method,
      params: params,
      id: Math.floor(Math.random() * 1000),
    },
    { headers: { 'Content-Type': 'application/json' } },
  )
}

export const UploaderClient = fileObject => {
  const formData = new FormData()
  formData.append('file', fileObject)
  return axios.post(`${process.env.REACT_APP_URL}/back/uploader`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const Client = (method, params = [], timeout = 0) => {
  return new Promise(function (resolve, reject) {
    window.setTimeout(() => {
      resolve(
        fetch(`${process.env.REACT_APP_URL}/back/jsonrpc`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            jsonrpc: '2.0',
            method: method,
            params: params,
            id: Math.floor(Math.random() * 1000),
          }),
        })
          .then(response => response.json())
          .catch(e => console.log(e)),
      )
    }, timeout)
  })
}

export default Client

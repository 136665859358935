import React, { Component } from 'react'
import { Button, Row, Col, Form, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { YMaps, Map, ZoomControl, Placemark, Polygon, GeoObject } from 'react-yandex-maps'
import Select from 'react-select'
import SearchIcon from '../icons/SearchIcon'
import BackIcon from '../icons/BackIcon'
import Client from '../json-rpc-client'
import TerritoryIcon from '../icons/TerritoryIcon'

const st = {
  org: 'orgList',
  terr: 'territoryList',
  sel: 'territoryListSelected',
  add: 'addTerritory',
  edit: 'editTerritory',
  draw: 'drawTerritory',
}

class Territories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // yandex maps
      ymaps: null,
      mapCenter: [62.028103, 129.732663],
      mapZoom: 12,
      polygonCoordinates: [[[]]],
      isDrawing: false,

      // right sidebar
      titlebarHeight: 0,
      orgList: [],
      orgListData: [],
      territoryList: [],
      territoryListAll: [],
      selectedOrg: {},
      selectedTerritory: {},

      // state pages
      status: st.org,

      // form
      territoryName: '',
      territoryDoctor: '',
      territoryCabinet: '',
      drawCoordinates: [[]],
      errorName: '',
      errorDoctor: '',
      errorCabinet: '',
      errorCoordinates: '',
      showConfirmation: false,
    }
  }

  componentDidMount() {
    Client('territory_getOrganizationList', { territoryType: this.props.type }).then(response => {
      if (response.error) {
        console.log(response.error)
      } else {
        this.setState({ orgListData: response.result, orgList: response.result })
      }
    })
    Client('territory_getTerritories', { territoryType: this.props.type }).then(response => {
      if (response.error) {
        console.log(response.error)
      } else {
        this.setState({ territoryListAll: response.result })
      }
    })
  }

  setYmaps = ymaps => {
    this.setState({ ymaps })
  }

  setCenterWithRef = ref => {
    const { ymaps } = this.state

    if (ymaps) {
      const map = ref.getMap()
      const result = ymaps.util.bounds.getCenterAndZoom(
        ref.geometry.getBounds(),
        map.container.getSize(),
      )

      // Setting the optimal center and zoom level of the map.
      map.setCenter(result.center, result.zoom)
    }
  }

  selectOrg = selectedOrg => {
    this.setState({ status: st.terr, selectedOrg, territoryList: [] }, () => {
      Client('territory_getOrganizationTerritories', {
        organizationId: selectedOrg.id,
        territoryType: this.props.type,
      }).then(response => {
        if (response.error) {
          console.log(response.error)
        } else {
          const polygonCoordinates = Array.from(response.result, territory => territory.coordinates)
          this.setState(
            {
              territoryList: response.result,
              polygonCoordinates,
              mapCenter: selectedOrg.coordinates,
              mapZoom: selectedOrg.zoom,
            },
            () => {
              // для вычисления размера сайдбар
              this.setState({ titlebarHeight: this.sidebarContent.clientHeight })
            },
          )
        }
      })
    })
  }

  selectTerritory = selectedTerritory => {
    this.setState(prevState => {
      let status = st.sel
      let territoryList = Array.from(prevState.territoryList, territory => {
        if (territory.id === selectedTerritory.id && territory.className === 'active') {
          status = st.terr
          territory.className = ''
        } else if (territory.id === selectedTerritory.id) {
          territory.className = 'active'
        } else {
          territory.className = ''
        }
        return territory
      })
      return { status, territoryList, selectedTerritory }
    })
  }

  searchOrgList = e => {
    const value = e.target.value.toLowerCase()
    const orgList = this.state.orgListData.filter(org => {
      return org.name.toLowerCase().includes(value)
    })

    this.setState({ orgList }, () => console.log(this.state.orgList, this.state.orgListData))
  }

  onClickAdd = () => {
    this.setState({
      status: st.add,
      territoryName: '',
      territoryDoctor: '',
      territoryCabinet: '',
    })
  }

  onClickEdit = () => {
    const { selectedTerritory } = this.state
    this.setState({
      status: st.edit,
      territoryName: selectedTerritory.name,
      territoryDoctor: selectedTerritory.doctor,
      territoryCabinet: selectedTerritory.cabinet,
      drawCoordinates: [selectedTerritory.coordinates],
    })
    this.startEditing()
  }

  onChangeInput = (e, key) => {
    this.setState({ [key]: e.target.value })
  }

  cancelForm = () => {
    const status = this.state.status === st.add ? st.terr : st.sel
    this.stopDrawing()
    this.setState({ status, drawCoordinates: [[]] })
  }

  clearErrors = () => {
    this.setState({
      errorName: '',
      errorDoctor: '',
      errorCabinet: '',
      errorCoordinates: '',
    })
  }

  drawPreparation = ref => {
    if (!this.drawRef) {
      this.drawRef = ref
      console.log('ref', ref)
      console.log('drawRef = ref', this.drawRef)
      this.drawRef.editor.events.add('drawingstop', event => {
        const drawCoordinates = [event.get('target').geometry._coordPath._coordinates[0]]
        this.setState({ drawCoordinates, isDrawing: false })
      })
      this.drawRef.editor.events.add('vertexdragend', event => {
        const drawCoordinates = [event.get('target').geometry._coordPath._coordinates[0]]
        this.setState({ drawCoordinates })
      })
      this.drawRef.editor.events.add('vertexadd', event => {
        const drawCoordinates = [event.get('target').geometry._coordPath._coordinates[0]]
        this.setState({ drawCoordinates })
      })
    }
  }

  startDrawing = () => {
    this.setState({ isDrawing: true })
    this.drawRef.editor.startDrawing()
  }

  startEditing = () => {
    this.drawRef.editor.startEditing()
  }

  stopDrawing = () => {
    this.drawRef.editor.stopDrawing()
  }

  isFormFilled = () => {
    const { territoryName, territoryDoctor, territoryCabinet, drawCoordinates } = this.state
    return !!(territoryName && territoryDoctor && territoryCabinet && drawCoordinates[0].length)
  }

  createTerritory = () => {
    this.clearErrors()
    Client('territory_store', {
      organizationId: this.state.selectedOrg.id,
      type: this.props.type,
      name: this.state.territoryName,
      doctor: this.state.territoryDoctor,
      cabinet: this.state.territoryCabinet,
      coordinates: this.state.drawCoordinates[0],
    }).then(response => {
      if (response.error) {
        console.log(response.error)
        if (response.error.code === 6000) {
          response.error.data.errors.forEach(error => {
            if (error.code === 'name') this.setState({ errorName: error.message })
            if (error.code === 'doctor') this.setState({ errorDoctor: error.message })
            if (error.code === 'cabinet') this.setState({ errorCabinet: error.message })
            if (error.code === 'coordinates') this.setState({ errorCoordinates: error.message })
          })
        }
      } else {
        console.log(response.result)
        this.selectOrg(this.state.selectedOrg)
        this.setState({ drawCoordinates: [[]] })
      }
    })
  }

  updateTerritory = () => {
    this.clearErrors()
    console.log(this.state.selectedTerritory)
    Client('territory_update', {
      id: this.state.selectedTerritory.id,
      name: this.state.territoryName,
      doctor: this.state.territoryDoctor,
      cabinet: this.state.territoryCabinet,
      coordinates: this.state.drawCoordinates[0],
    }).then(res => {
      if (res.error) {
        console.log(res.error)
        if (res.error.code === 6000) {
          res.error.data.errors.forEach(error => {
            if (error.code === 'name') this.setState({ errorName: error.message })
            if (error.code === 'doctor') this.setState({ errorDoctor: error.message })
            if (error.code === 'cabinet') this.setState({ errorCabinet: error.message })
            if (error.code === 'coordinates') this.setState({ errorCoordinates: error.message })
          })
        }
      } else {
        console.log(res.result)
        this.selectOrg(this.state.selectedOrg)
        this.setState({ drawCoordinates: [[]] })
      }
    })
  }

  deleteTerritory = () => {
    this.clearErrors()
    Client('territory_destroy', {
      id: this.state.selectedTerritory.id,
    }).then(res => {
      if (res.error) {
        console.log(res.error)
      } else {
        console.log(res.result)
        this.closeConfirmation()
        this.selectOrg(this.state.selectedOrg)
        this.setState({ drawCoordinates: [[]] })
      }
    })
  }

  closeConfirmation = () => this.setState({ showConfirmation: false })

  showConfirmation = () => this.setState({ showConfirmation: true })

  renderModalConfirmation = () => {
    const { showConfirmation, selectedTerritory } = this.state
    return (
      <>
        <Modal show={this.state.showConfirmation} onHide={this.closeConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title>
              Удалить <span className="font-weight-bold">{selectedTerritory.name}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Вы действительно хотите удалить{' '}
            <span className="font-weight-bold">{selectedTerritory.name}</span>? <br />
            Действие неотвратимо.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeConfirmation}>
              Отменить
            </Button>
            <Button variant="danger" onClick={this.deleteTerritory}>
              Удалить
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  renderMap = () => {
    return (
      <YMaps>
        <Map
          lang="ru-RU"
          modules={['util.bounds', 'borders', 'ObjectManager', 'geoObject.addon.editor']}
          className="territory-map"
          state={{
            center: this.state.mapCenter,
            zoom: this.state.mapZoom,
          }}
          onLoad={ymaps => this.setYmaps(ymaps)}
        >
          <ZoomControl />
          {this.renderTerritoryListPolygon()}
          {this.renderDrawPolygon()}
        </Map>
      </YMaps>
    )
  }

  renderDrawPolygon = () => {
    return (
      <Polygon
        instanceRef={ref => ref && this.drawPreparation(ref)}
        geometry={this.state.drawCoordinates}
        options={{
          editorDrawingCursor: 'crosshair',
          fillColor: this.props.color,
          strokeColor: this.props.color,
          strokeOpacity: 0.7,
          fillOpacity: 0.1,
          strokeWidth: 2,
          visible: true,
          geodesic: true,
        }}
      />
    )
  }

  renderTerritoryListPolygon = () => {
    const { status, mapCenter, territoryList, territoryListAll, selectedTerritory } = this.state
    if (status === st.terr || status === st.add || status === st.edit) {
      return (
        <>
          {territoryList.map((territory, i) => {
            if (status === st.edit && territory.id === selectedTerritory.id) return true
            return (
              <Polygon
                key={i}
                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                geometry={[territory.coordinates]}
                // instanceRef={ref => ref && this.setCenterWithRef(ref)}
                options={{
                  fillColor: this.props.color,
                  strokeColor: this.props.color,
                  strokeOpacity: 0.7,
                  fillOpacity: 0.02,
                  strokeWidth: 2,
                }}
                properties={{
                  balloonContentHeader: territory.name,
                  balloonContentBody: `<div>Врач: ${territory.doctor}</div><div>${territory.cabinet}</div>`,
                  balloonContentFooter: '',
                }}
              />
            )
          })}
          <Placemark geometry={mapCenter} />
        </>
      )
    } else if (status === st.sel) {
      return (
        <>
          <Polygon
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            geometry={[selectedTerritory.coordinates]}
            instanceRef={ref => ref && this.setCenterWithRef(ref)}
            options={{
              fillColor: this.props.color,
              strokeColor: this.props.color,
              strokeOpacity: 0.7,
              fillOpacity: 0.02,
              strokeWidth: 2,
            }}
            properties={{
              balloonContentHeader: selectedTerritory.name,
              balloonContentBody: `<div>Врач: ${selectedTerritory.doctor}</div><div>${selectedTerritory.cabinet}</div>`,
              balloonContentFooter: '',
            }}
          />
          <Placemark geometry={mapCenter} />
        </>
      )
    } else if (status === st.org) {
      return (
        <>
          {territoryListAll.map((territory, i) => (
            <Polygon
              key={i}
              modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
              geometry={[territory.coordinates]}
              // instanceRef={ref => ref && this.setCenterWithRef(ref)}
              options={{
                fillColor: this.props.color,
                strokeColor: this.props.color,
                strokeOpacity: 0.7,
                fillOpacity: 0.02,
                strokeWidth: 2,
              }}
              properties={{
                balloonContentHeader: territory.name,
                balloonContentBody: `
                  <div>${territory.organizationName}</div>
                  <div>Врач: ${territory.doctor}</div>
                  <div>${territory.cabinet}</div>
                  `,
                balloonContentFooter: '',
              }}
            />
          ))}
        </>
      )
    } else {
      return true
    }
  }

  renderRightSidebar = () => {
    const { status } = this.state
    if (status === st.org) {
      return this.renderSidebarOrgList()
    } else if (st.sel.includes(status)) {
      return this.renderSidebarTerritories()
    } else if (status === st.add) {
      return this.renderSidebarTerrForm()
    } else if (status === st.edit) {
      return this.renderSidebarTerrForm()
    }
  }

  renderSidebarOrgList = () => {
    return (
      <>
        <div className="header-bar">
          <input onChange={e => this.searchOrgList(e)} placeholder="Поиск медучреждения" />
          <div className="icon">
            <SearchIcon />
          </div>
        </div>
        <div className="org-list">
          {this.state.orgList.map((org, i) => {
            return (
              <div key={i} className="org" onClick={() => this.selectOrg(org)}>
                <div className="name">{org.name}</div>
                <div className="territories-count">{org.territoriesCount}</div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  renderSidebarTerritories = () => {
    return (
      <>
        <div
          className="header-bar"
          ref={sidebarContent => {
            this.sidebarContent = sidebarContent
          }}
        >
          <div className="back-icon" onClick={() => this.setState({ status: st.org })}>
            <BackIcon />
          </div>
          <span className="title">{this.state.selectedOrg.name}</span>
        </div>
        <div
          className="territory-list"
          style={{ height: `calc(100% - ${this.state.titlebarHeight}px)` }}
        >
          {this.state.territoryList.map((territory, i) => {
            return (
              <div
                key={i}
                className={`territory ${territory.className}`}
                onClick={() => this.selectTerritory(territory)}
              >
                <div className="name">{territory.name}</div>
                <div className="doctor">{territory.doctor}</div>
                <div className="cabinet">{territory.cabinet}</div>
              </div>
            )
          })}
        </div>
      </>
    )
  }

  renderSidebarTerrForm = () => {
    const {
      status,
      isDrawing,
      selectedOrg,
      titlebarHeight,
      territoryName,
      territoryDoctor,
      territoryCabinet,
      errorName,
      errorDoctor,
      errorCabinet,
      errorCoordinates,
    } = this.state
    return (
      <>
        <div
          className="header-bar"
          ref={ref => {
            this.sidebarContent = ref
          }}
        >
          <div className="back-icon" onClick={this.cancelForm}>
            <BackIcon />
          </div>
          <span className="title">{selectedOrg.name}</span>
        </div>
        <div className="territory-form" style={{ height: `calc(100% - ${titlebarHeight}px)` }}>
          <div className="title">Добавить участок</div>
          <div className="other-text">
            Отрисуйте территорию и добавьте информацию об этом участке. Информация будет
            опубликована в общем доступе.
          </div>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Название участка *"
              value={territoryName}
              onChange={e => this.onChangeInput(e, 'territoryName')}
              disabled={isDrawing}
              className={errorName && 'is-invalid'}
            />
            <div className="invalid-feedback d-block">{errorName}</div>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="ФИО врача *"
              value={territoryDoctor}
              onChange={e => this.onChangeInput(e, 'territoryDoctor')}
              disabled={isDrawing}
              className={errorDoctor && 'is-invalid'}
            />
            <div className="invalid-feedback d-block">{errorDoctor}</div>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Кабинет врача *"
              value={territoryCabinet}
              onChange={e => this.onChangeInput(e, 'territoryCabinet')}
              disabled={isDrawing}
              className={errorCabinet && 'is-invalid'}
            />
            <div className="invalid-feedback d-block">{errorCabinet}</div>
          </Form.Group>
          {this.renderDrawButton()}
          <div className="invalid-feedback d-block">{errorCoordinates}</div>
          <div className="other-text">
            Добавьте территорию объекта, чтобы пациенты могли легко найти к какому участку они
            прикреплены
          </div>
          <div className="other-text">Как отрисовать территорию?</div>
          <ol type="1" className="other-text">
            <li className="other-text">
              Нажмите на "Отрисовать территорию", а затем кликните поочередно левой кнопкой мыши по
              карте, чтобы выделить территорию
            </li>
            <li className="other-text">
              Кликните левой кнопкой мыши чтобы “Завершить/Удалить/Добавить внутренний контур”
            </li>
            <li className="other-text">
              После внесенных изменений нажите на “Сохранить” в правом нижнем углу
            </li>
          </ol>
          <div className="buttons">
            <Button variant="link" onClick={this.cancelForm}>
              Отмена
            </Button>
            {this.renderSaveButton()}
          </div>
        </div>
      </>
    )
  }

  renderHeaderButton = () => {
    const { status } = this.state
    switch (status) {
      case st.org:
        return true
      case st.terr:
        return (
          <Button
            onClick={this.onClickAdd}
            style={{ width: '140px', marginLeft: '20px' }}
            variant="outline-primary"
          >
            Добавить
          </Button>
        )
      case st.sel:
        return (
          <>
            <Button
              onClick={this.onClickAdd}
              style={{ width: '140px', marginLeft: '20px' }}
              variant="outline-primary"
            >
              Добавить
            </Button>
            <Button
              onClick={this.onClickEdit}
              style={{ width: '140px', marginLeft: '20px' }}
              variant="outline-primary"
            >
              Редактировать
            </Button>
            <Button
              onClick={this.showConfirmation}
              style={{ width: '140px', marginLeft: '20px' }}
              variant="outline-danger"
            >
              Удалить
            </Button>
          </>
        )
      default:
        return true
    }
  }

  renderSaveButton = () => {
    const { status, isDrawing } = this.state
    if (status === st.add) {
      return (
        <Button
          onClick={() => this.createTerritory()}
          variant={this.isFormFilled() ? 'outline-primary' : 'secondary'}
          disabled={!this.isFormFilled() || isDrawing}
        >
          Добавить
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => this.updateTerritory()}
          variant={this.isFormFilled() ? 'outline-primary' : 'secondary'}
          disabled={!this.isFormFilled() || isDrawing}
        >
          Обновить
        </Button>
      )
    }
  }

  renderDrawButton = () => {
    if (this.state.isDrawing) {
      return (
        <Button variant="primary" block onClick={this.stopDrawing}>
          <TerritoryIcon />
          <span>Закончить отрисовку</span>
        </Button>
      )
    } else {
      return (
        <Button variant="outline-primary" block onClick={this.startDrawing}>
          <TerritoryIcon />
          <span>Отрисовать территорию</span>
        </Button>
      )
    }
  }

  render() {
    return (
      <>
        <div className="admin-content">
          <div className="admin-content-header">
            <span>Участки {this.props.typeName}</span>
            {this.renderHeaderButton()}
          </div>
          <div className="territory-container">
            {this.renderMap()}
            <div className="territory-orgs">{this.renderRightSidebar()}</div>
          </div>
        </div>
        {this.renderModalConfirmation()}
      </>
    )
  }
}

export default Territories

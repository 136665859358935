import React, { Component } from 'react'
import { Container, Table, Button, Modal, InputGroup, FormControl, Form } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import EditIcon from '../icons/EditIcon'

class Connections extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'id',
          text: 'ID',
          sort: true,
        },
        {
          dataField: 'name',
          text: 'Типы интернет связи',
          headerStyle: { minWidth: '400px' },
          sort: true,
        },
        {
          dataField: 'description',
          text: 'Описание',
        },
        {
          dataField: 'icon',
          text: 'Иконка',
          formatter: (cellContent, row) => {
            return <img src={process.env.REACT_APP_URL + row.icon} width={25} height={25} />
          },
        },
        {
          dataField: 'df1',
          isDummyField: true,
          text: '',
          style: { textAlign: 'right' },
          formatter: (cellContent, row) => {
            return (
              <span className="edit-icon" onClick={this.changeTableInternets.bind(this, row)}>
                <EditIcon />
              </span>
            )
          },
        },
      ],

      defaultSorted: [
        {
          dataField: 'id',
          order: 'asc',
        },
      ],

      kindInternets: [],
      modalShow: false,
      modalMode: '',
      changeRowId: '',
      valueName: '',
      valueDescription: '',
      valueIcon: '',
      errorName: '',
      errorDescription: '',
      errorIcon: '',
    }
  }

  fetchInternets = () => {
    console.log('update inet')
    fetch(`${process.env.REACT_APP_URL}/api/connections`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          kindInternets: result,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  fetchAddData = () => {
    let formData = new FormData()
    formData.append('name', this.state.valueName)
    formData.append('description', this.state.valueDescription)
    formData.append('icon', this.state.valueIcon)

    fetch(`${process.env.REACT_APP_URL}/api/connection/store`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json' },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          alert('Новый тип связи успешно добавлен')
          this.setState({
            modalShow: false,
            errorName: '',
            errorDescription: '',
            errorIcon: '',
          })
          this.fetchInternets()
        } else {
          alert('Произошла ошибка')
          console.log(response.errors)
          let errorName, errorDescription, errorIcon
          errorName = response.errors.name ? response.errors.name[0] : ''
          errorDescription = response.errors.description ? response.errors.description[0] : ''
          errorIcon = response.errors.icon ? response.errors.icon[0] : ''

          this.setState({ errorName, errorDescription, errorIcon })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        // this.setModalShow(false)
        console.log(e, 44444)
      })
  }

  fetchChangeData = () => {
    let formData = new FormData()
    formData.append('name', this.state.valueName)
    formData.append('description', this.state.valueDescription)
    formData.append('icon', this.state.valueIcon)

    fetch(`${process.env.REACT_APP_URL}/api/connection/${this.state.changeRowId}/update`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json' },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          alert('Изменения успешно внесены')
          this.setState({
            modalShow: false,
            errorName: '',
            errorDescription: '',
            errorIcon: '',
          })
          this.fetchInternets()
        } else {
          alert('Произошла ошибка')
          console.log(response.errors)
          let errorName, errorDescription, errorIcon
          errorName = response.errors.name ? response.errors.name[0] : ''
          errorDescription = response.errors.description ? response.errors.description[0] : ''
          errorIcon = response.errors.icon ? response.errors.icon[0] : ''

          this.setState({ errorName, errorDescription, errorIcon })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        this.setModalShow(false)
        console.log(e)
      })
  }

  componentDidMount() {
    this.setState({
      kindInternets: this.props.data,
    })
  }

  changeTableInternets = data => {
    this.setModalShow(true)
    this.setState({
      modalMode: 'edit',
      changeRowId: data.id,
      valueName: data.name,
      valueDescription: data.description,
    })
  }

  addTableInternets = () => {
    this.setModalShow(true)
    this.setState({
      modalMode: 'add',
      changeRowId: '',
      valueName: '',
      valueDescription: '',
      valueIcon: '',
    })
  }

  saveChangeTable = () => {
    if (this.state.modalMode == 'add') {
      this.fetchAddData()
    } else {
      this.fetchChangeData()
    }
  }

  setModalShow = data => {
    this.setState({
      modalShow: data,
    })
  }

  deleteTableRow = () => {
    fetch(`${process.env.REACT_APP_URL}/api/connection/${this.state.changeRowId}/destroy`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { Accept: 'application/json' },
    })
      .then(response => response.json())
      .then(result => {
        alert('Тип связи удалён')
        this.setModalShow(false)
        this.fetchInternets()
      })
      .catch(e => {
        alert('Произошла ошибка')
        this.setModalShow(false)
        console.log(e)
      })
  }

  MyVerticallyCenteredModal() {
    return (
      <Modal
        show={this.state.modalShow}
        onHide={() => this.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.modalMode == 'edit' ? 'Редактировать' : 'Добавить'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              value={this.state.valueName}
              placeholder="Тип интернет связи"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className={this.state.errorName ? 'is-invalid' : ''}
              onChange={data => this.setState({ valueName: data.target.value })}
            />
            <div className="invalid-feedback">{this.state.errorName}</div>
          </InputGroup>
          <InputGroup className="mb-3">
            <FormControl
              value={this.state.valueDescription}
              placeholder="Описание"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className={this.state.errorDescription ? 'is-invalid' : ''}
              onChange={data => this.setState({ valueDescription: data.target.value })}
            />
            <div className="invalid-feedback">{this.state.errorDescription}</div>
          </InputGroup>
          <Form.Group>
            <Form.File
              id="file-edit"
              label="Иконка"
              className={this.state.errorIcon ? 'is-invalid' : ''}
              onChange={data => this.setState({ valueIcon: data.target.files[0] })}
            />
            <div className="invalid-feedback">{this.state.errorIcon}</div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {this.state.modalMode == 'edit' ? (
            <Button onClick={this.deleteTableRow} variant="danger">
              Удалить
            </Button>
          ) : null}
          <Button onClick={this.saveChangeTable}>Сохранить</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    return (
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Типы связи</span>
          <Button
            onClick={this.addTableInternets}
            style={{ width: '140px', marginLeft: '20px' }}
            variant="outline-primary"
          >
            Добавить
          </Button>
        </div>
        <BootstrapTable
          bordered={false}
          headerWrapperClasses="table-header"
          bodyClasses="table-body"
          rowClasses="table-row"
          bootstrap4
          keyField="asd"
          id="admin-table-internets"
          data={this.state.kindInternets}
          columns={this.state.columns}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 25,
          })}
          defaultSorted={this.state.defaultSorted}
        />
        {this.MyVerticallyCenteredModal()}
      </div>
    )
  }
}

export default Connections


import Feed from './Feed';


function Map() {
    return [
        <Feed/>
    ]
}

export default Map;
const EditIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.625 4.125L17.875 1.375L7.39062 12.5469L10.1406 15.2969L20.625 4.125ZM6.10156 13.75C4.53234 13.75 3.26562 15.0661 3.26562 16.6964C3.26562 17.7891 1.93359 18.6609 1.375 18.6609C2.24469 19.8589 3.72883 20.625 5.15625 20.625C7.24539 20.625 8.9375 18.8671 8.9375 16.6964C8.9375 15.0661 7.67078 13.75 6.10156 13.75Z"
        fill="#D6D6D6"
      />
    </svg>
  )
}

export default EditIcon

import React, { Component } from 'react'
import { Container, Row, Col, Form, Tabs, Tab, Button } from 'react-bootstrap'
import {
  YMaps,
  Map,
  ZoomControl,
  Placemark,
  Polygon,
  GeoObject,
  Clusterer,
  SearchControl,
} from 'react-yandex-maps'
import Select from 'react-select'
import { FaHeart, FaBars } from 'react-icons/fa'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import Logo from '../../img/logo-on-light.svg'
import Client from '../json-rpc-client'

class Feed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      kindInternets: [],
      kindHospitals: [],
      dataHospitals: [],
      dataDistricts: [],
      dataCities: [],
      dataTypes: [],
      dataInternets: [],
      optionsHospitals: [],
      optionsDistricts: [],
      optionsCities: [],
      optionsTypes: [],
      mapCenter: [62.028103, 129.732663],
      placemarkArr: [],
      legendShow: false,
      valueDistricts: '',
      valueHospitals: '',
      valueCities: '',
      valueTypes: '',
      internetConditions: [],
      hospitalConditions: [],
      loading: true,
      selectStyle: {
        option: styles => ({ ...styles, color: '#3f3b80' }),
        control: styles => ({
          ...styles,
          color: '#3f3b80',
          backgroundColor: '#fbfcfd',
          borderWidth: 0,
        }),
        // placeholder: styles => ({ ...styles, color:'#3f3b80' }),
        container: styles => ({
          ...styles,
          color: '#3f3b80',
          marginBottom: 10,
        }),
        clearIndicator: styles => ({ ...styles, color: '#6e6a94' }),
        singleValue: styles => ({ ...styles, color: '#3f3b80' }),
        dropdownIndicator: styles => ({ ...styles, color: '#6e6a94' }),
        input: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            color: '#3f3b80',
          }
        },
      },
      toggleSidebar: false,
      startClientX: 0,

      territoryList: [],
    }

    this.type = {
      therapeutic: { name: 'Терапевтический', color: '#FD7669' },
      pediatric: { name: 'Педиатрический', color: '#0000FF' },
    }
  }

  componentDidMount() {
    Client('territory_getList', [], 1000).then(res => {
      if (res.result) this.setState({ territoryList: res.result })
      else console.log(res.error)
    })
    fetch(`${process.env.REACT_APP_URL}/api/organizations`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        console.log(result, 123)
        this.setState({
          placemarkArr: result.organizations,
          kindInternets: result.connections,
          dataInternets: result.connections,
          optionsHospitals: result.organizations,
          optionsDistricts: result.districts,
          optionsCities: result.settlements,
          dataHospitals: result.organizations,
          dataDistricts: result.districts,
          dataCities: result.settlements,
          kindHospitals: result.types,
          optionsTypes: result.types,
          dataTypes: result.types,
          loading: false,
        })
      })
      .catch(e => {
        this.setState({
          loading: false,
        })
      })
  }

  onClickPlacemark = data => {
    this.changeCoordinate(data, 'select', 'valueHospitals')
  }

  asd = () => {
    return [
      `<div style='display: flex; width: 100%; height: 100%; flex-direction: row'>
            <div style='width: 30%; word-wrap: break-word; padding-right: 5px;'>
                ASDASDASDASDASDASD
                ASDASDASDASDASDASD
                ASDASDASDASDASDASD
            </div>
  <div style='width: 70%; word-wrap: break-word; border-left: 1px solid #d6d6d6; padding-left: 10px;'>
            ASDASDASDASDASDASD
            ASDASDASDASDASDASD
            ASDASDASDASDASDASD
            ASDASDASDASDASDASD
            
            </div>
            </div>`,
    ]
  }

  mapPlacemark = () => {
    if (this.state.placemarkArr) {
      return this.state.placemarkArr.map((l, i) => {
        // return <Placemark onClick={this.onClickPlacemark.bind(this, l)} instanceRef={(ref)=>this[`placemark${l.id}`] = ref} options={{ iconLayout: 'default#image', iconImageHref: this.findPlacemarkIcon(l.connection_id), iconImageSize: [25, 25],}} modules={['geoObject.addon.balloon', 'geoObject.addon.hint']} properties={{balloonContentBody: this.asd(), balloonContentFooter: l.contacts, hintContent: l.label}} geometry={l.coordinates} />

        return (
          <Placemark
            onClick={this.onClickPlacemark.bind(this, l)}
            instanceRef={ref => (this[`placemark${l.id}`] = ref)}
            options={{
              iconLayout: 'default#image',
              iconImageHref: this.findPlacemarkIcon(l.connection_id),
              iconImageSize: [25, 25],
            }}
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            properties={{
              balloonContentHeader: l.label,
              balloonContentBody: l.address,
              balloonContentFooter: l.contacts,
              hintContent: l.label,
            }}
            geometry={l.coordinates}
          />
        )
      })
    }

    return null
  }

  mapPolygon = () => {
    if (this.state.placemarkArr) {
      return this.state.placemarkArr.map((l, i) => {
        if (l.territory.length > 0) {
          return (
            <GeoObject
              geometry={{
                type: 'Polygon',
                coordinates: [l.territory],
              }}
              properties={{
                balloonContentHeader: l.label,
                balloonContentBody: 'Территория ответственности',
                balloonContentFooter: '',
                hintContent: l.label,
              }}
              options={{
                fillColor: '#FD7669',
                strokeColor: '#FFDCD9',
                // Общая прозрачность (как для заливки, так и для обводки).
                strokeOpacity: 1,
                fillOpacity: 0.3,
                // Ширина обводки.
                strokeWidth: 2,
                visible: true,
                geodesic: true,
              }}
            />
          )
        } else return null
      })
    } else return null
  }

  findPlacemarkIcon = id => {
    let icon = this.state.kindInternets.find(x => x.id == id).icon
    return process.env.REACT_APP_URL + icon
  }

  changeCoordinate = (data, type, from) => {
    if (type != 'clear') {
      this.setState({
        [from]: data,
      })

      switch (from) {
        case 'valueDistricts':
          this.selectDistricts(data)
          break
        case 'valueHospitals':
          this.selectHospitals(data)
          break
        case 'valueCities':
          this.selectCities(data)
          break
        case 'valueTypes':
          this.selectTypes(data)
          break
        default:
          break
      }

      this.mapRef.setCenter(data.coordinates, data.zoom)

      if (from == 'valueHospitals') {
        if (this[`placemark${data.id}`]) {
          this[`placemark${data.id}`].balloon.open()
        }
      }
    } else {
      switch (from) {
        case 'valueDistricts':
          this.clearDistricts(data)
          break
        case 'valueCities':
          this.clearCities(data)
          break
        case 'valueTypes':
          this.clearTypes(data)
          break
        default:
          break
      }
      this.setState({
        [from]: '',
      })
    }
    if (this.mapRef.balloon.isOpen()) {
      this.mapRef.balloon.close()
    }
  }

  clearDistricts = () => {
    if (this.state.internetConditions.length > 0) {
      this.checkCheckboxFilter(this.state.dataHospitals)
      this.setState({
        optionsHospitals: this.state.dataHospitals,
        valueHospitals: '',
        valueCities: '',
      })
    } else {
      this.setState({
        optionsHospitals: this.state.dataHospitals,
        optionsDistricts: this.state.dataDistricts,
        optionsCities: this.state.dataCities,
        placemarkArr: this.state.dataHospitals,
        valueHospitals: '',
        valueCities: '',
      })
    }
    this.setState({ valueTypes: '' })
    this.resetTypes()
  }

  clearCities = () => {
    if (this.state.valueDistricts != '') {
      let hospitals = this.filterDataInArray(
        this.state.dataHospitals,
        'district_id',
        this.state.valueDistricts.id,
      )
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: '',
        })
      } else {
        this.setState({
          optionsHospitals: hospitals,
          placemarkArr: hospitals,
          valueHospitals: '',
        })
      }
      this.setState({
        valueTypes: '',
      })
      this.setTypesOptions(hospitals)
      this.setState({ valueTypes: '' })
    } else {
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(this.state.dataHospitals)
        this.setState({
          optionsHospitals: this.state.dataHospitals,
          valueHospitals: '',
        })
      } else {
        this.setState({
          optionsHospitals: this.state.dataHospitals,
          optionsCities: this.state.dataCities,
          placemarkArr: this.state.dataHospitals,
          valueHospitals: '',
        })
      }
      this.setState({ valueTypes: '' })
      this.resetTypes()
    }
  }

  clearTypes = () => {
    if (this.state.valueDistricts == '' && this.state.valueCities == '') {
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(this.state.dataHospitals)
        this.setState({
          optionsHospitals: this.state.dataHospitals,
          valueHospitals: '',
        })
      } else {
        this.setState({
          optionsHospitals: this.state.dataHospitals,
          placemarkArr: this.state.dataHospitals,
          valueHospitals: '',
        })
      }
    } else if (
      (this.state.valueDistricts != '' && this.state.valueCities != '') ||
      this.state.valueCities != ''
    ) {
      let hospitals = this.filterDataInArray(
        this.state.dataHospitals,
        'settlement_id',
        this.state.valueCities.id,
      )
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: '',
        })
      } else {
        this.setState({
          optionsHospitals: hospitals,
          placemarkArr: hospitals,
          valueHospitals: '',
        })
      }
    } else if (this.state.valueDistricts != '') {
      let hospitals = this.filterDataInArray(
        this.state.dataHospitals,
        'district_id',
        this.state.valueDistricts.id,
      )
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: '',
        })
      } else {
        this.setState({
          optionsHospitals: hospitals,
          placemarkArr: hospitals,
          valueHospitals: '',
        })
      }
    }
  }

  checkCheckboxFilter = (hospitals, citiy = false) => {
    let citiesArr = citiy ? citiy : this.state.dataCities
    let result = []
    let cities = []
    let districts = []
    this.state.internetConditions.map(k => {
      hospitals.map(l => {
        if (l.connection_id == k) {
          let district = this.findDataInArray(this.state.dataDistricts, 'id', l.district_id)
          let city = this.findDataInArray(citiesArr, 'id', l.settlement_id)
          cities.push(city)
          districts.push(district)
          result.push(l)
        }
      })
    })
    this.setState({
      placemarkArr: result.length == 0 ? this.state.optionsHospitals : result,
      optionsDistricts: result.length == 0 ? this.state.dataDistricts : [...new Set(districts)],
      optionsCities: result.length == 0 ? this.state.dataCities : [...new Set(cities)],
      // optionsHospitals: (result.length == 0) ? this.state.optionsHospitals : result
    })
  }

  selectDistricts = data => {
    let hospitals = this.filterDataInArray(this.state.dataHospitals, 'district_id', data.id)
    let cities = this.filterDataInArray(this.state.dataCities, 'district_id', data.id)
    if (this.state.internetConditions.length > 0) {
      this.checkCheckboxFilter(hospitals, cities)
      this.setState({
        optionsHospitals: hospitals,
        valueHospitals: '',
        valueCities: '',
        valueTypes: '',
      })
    } else {
      this.setState({
        placemarkArr: hospitals,
        optionsHospitals: hospitals,
        optionsCities: cities,
        valueHospitals: '',
        valueCities: '',
        valueTypes: '',
      })
    }
    this.setTypesOptions(hospitals)
  }

  setTypesValue = data => {
    let type = this.findDataInArray(this.state.dataTypes, 'id', data.type_id)
    this.setState({
      valueTypes: type,
    })
  }

  setTypesOptions = hospitals => {
    let types = []
    hospitals.map(l => {
      let data = this.state.dataTypes.find(res => res.id == l.type_id)
      if (data) {
        types.push(data)
      }
    })
    this.setState({
      optionsTypes: [...new Set(types)],
    })
  }

  resetTypes = () => {
    this.setState({
      optionsTypes: this.state.dataTypes,
      valueTypes: '',
    })
  }

  selectHospitals = data => {
    let hospital = []
    hospital.push(data)
    if (this.state.valueDistricts == '' && this.state.valueCities == '') {
      let cities = this.filterDataInArray(this.state.dataCities, 'district_id', data.district_id)
      let district = this.findDataInArray(this.state.dataDistricts, 'id', data.district_id)
      let city = this.findDataInArray(this.state.dataCities, 'id', data.settlement_id)
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospital, cities)
        this.setState({
          valueDistricts: district,
          valueCities: city,
          optionsHospitals: hospital,
        })
      } else {
        this.setState({
          valueDistricts: district,
          valueCities: city,
          optionsCities: cities,
          optionsHospitals: hospital,
          placemarkArr: hospital,
        })
      }
    } else if (this.state.valueDistricts == '') {
      let district = this.findDataInArray(this.state.dataDistricts, 'id', data.district_id)
      this.setState({
        valueDistricts: district,
      })
    } else if (this.state.valueCities == '') {
      let cities = this.filterDataInArray(this.state.dataCities, 'district_id', data.district_id)
      let city = this.findDataInArray(this.state.dataCities, 'id', data.settlement_id)
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospital, cities)
        this.setState({
          valueCities: city,
          optionsHospitals: hospital,
        })
      } else {
        this.setState({
          valueCities: city,
          optionsCities: cities,
          optionsHospitals: hospital,
          placemarkArr: hospital,
        })
      }
    }

    if (this.state.valueTypes == '') {
      this.setTypesValue(data)
    }
  }

  selectCities = data => {
    if (this.state.valueDistricts == '') {
      let district = this.findDataInArray(this.state.dataDistricts, 'id', data.district_id)
      let hospitals = this.filterDataInArray(this.state.dataHospitals, 'settlement_id', data.id)
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          valueDistricts: district,
          optionsHospitals: hospitals,
          valueHospitals: '',
          valueTypes: '',
        })
      } else {
        this.setState({
          valueDistricts: district,
          optionsHospitals: hospitals,
          placemarkArr: hospitals,
          valueHospitals: '',
          valueTypes: '',
        })
      }
      this.setTypesOptions(hospitals)
    } else if (this.state.valueDistricts != '') {
      let hospitals = this.filterDataInArray(this.state.dataHospitals, 'settlement_id', data.id)
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: '',
          valueTypes: '',
        })
      } else {
        this.setState({
          optionsHospitals: hospitals,
          placemarkArr: hospitals,
          valueHospitals: '',
          valueTypes: '',
        })
      }
      this.setTypesOptions(hospitals)
    }
  }

  selectTypes = data => {
    if (this.state.valueDistricts == '' && this.state.valueCities == '') {
      let hospitals = this.filterDataInArray(this.state.dataHospitals, 'type_id', data.id)
      let valueHospitals = this.state.valueHospitals
      if (hospitals.length == 0 && this.state.valueHospitals) {
        valueHospitals = ''
      }
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals)
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: valueHospitals,
        })
      } else {
        this.setState({
          optionsHospitals: hospitals,
          valueHospitals: valueHospitals,
          placemarkArr: hospitals,
        })
      }
    } else if (
      (this.state.valueDistricts != '' && this.state.valueCities != '') ||
      this.state.valueCities != ''
    ) {
      let hospitals_data = this.filterDataInArray(
        this.state.dataHospitals,
        'settlement_id',
        this.state.valueCities.id,
      )
      let hospitals_type = this.filterDataInArray(hospitals_data, 'type_id', data.id)
      let valueHospitals = this.state.valueHospitals
      if (hospitals_type.length == 0 && this.state.valueHospitals) {
        valueHospitals = ''
      }
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals_type)
        this.setState({
          optionsHospitals: hospitals_type,
          valueHospitals: valueHospitals,
        })
      } else {
        this.setState({
          optionsHospitals: hospitals_type,
          valueHospitals: valueHospitals,
          placemarkArr: hospitals_type,
        })
      }
    } else if (this.state.valueDistricts != '') {
      let hospitals_data = this.filterDataInArray(
        this.state.dataHospitals,
        'district_id',
        this.state.valueDistricts.id,
      )
      let hospitals_type = this.filterDataInArray(hospitals_data, 'type_id', data.id)
      let valueHospitals = this.state.valueHospitals
      if (hospitals_type.length == 0 && this.state.valueHospitals) {
        valueHospitals = ''
      }
      if (this.state.internetConditions.length > 0) {
        this.checkCheckboxFilter(hospitals_type)
        this.setState({
          optionsHospitals: hospitals_type,
          valueHospitals: valueHospitals,
        })
      } else {
        this.setState({
          optionsHospitals: hospitals_type,
          placemarkArr: hospitals_type,
          valueHospitals: valueHospitals,
        })
      }
    }
  }

  resetFilter = () => {
    this.resetCheckbox()
    this.setState({
      optionsHospitals: this.state.dataHospitals,
      optionsDistricts: this.state.dataDistricts,
      optionsCities: this.state.dataCities,
      placemarkArr: this.state.dataHospitals,
      kindInternets: this.state.dataInternets,
      valueDistricts: '',
      valueHospitals: '',
      valueCities: '',
      valueTypes: '',
    })

    if (this.mapRef.balloon.isOpen()) {
      this.mapRef.balloon.close()
    }

    this.mapRef.setCenter(this.state.mapCenter, 4)
  }

  resetCheckbox = () => {
    this.state.kindInternets.map(l => {
      l.checked = false
    })
    this.setState({
      kindInternets: this.state.kindInternets,
      internetConditions: [],
    })
  }

  filterDataInArray = (arr, key, id) => {
    let data = arr.filter(data => {
      return data[key] == id
    })
    // let data = this.state[arr].find(x => x[key] == id);
    return data
  }

  findDataInArray = (arr, key, id) => {
    let data = arr.find(x => x[key] == id)
    return data
  }

  getRegions = ymaps => {
    console.log(ymaps, this.mapRef)
  }

  // filterCondition = (res, tabName) => {
  //     let conditionState = (tabName == 'kindInternets') ? 'internetConditions' : 'hospitalConditions'
  //     let arr = []
  //     this.state[tabName].map((l)=>{
  //         if (l.checked) {
  //             arr.push(l.id)
  //         }
  //     })
  //     return arr
  // }

  // checkboxFilter = (checked, data, tabName) => {
  //     data.checked = checked
  //     let conditions = this.filterCondition(data, tabName)
  //     let kindTab = (tabName == 'kindInternets') ? 'connection_id' : 'type_id'
  //     let result = []
  //     console.log(this.state.conditionsS, this.state.conditionsSS)
  //     conditions.map((k)=>{
  //         this.state.optionsHospitals.map((l)=>{
  //             if (l[kindTab] == k) {
  //                 result.push(l)
  //             }
  //         })
  //     })
  //     this.setState({
  //         placemarkArr: (result.length == 0) ? this.state.optionsHospitals : result
  //     })
  // }

  filterCondition = res => {
    let arr = []
    this.state.kindInternets.map(l => {
      if (l.checked) {
        arr.push(l.id)
      }
    })
    return arr
  }

  checkboxFilter = (checked, data, i) => {
    data.checked = checked
    let conditions = this.filterCondition(data)
    let result = []
    let cities = []
    let districts = []
    conditions.map(k => {
      this.state.optionsHospitals.map(l => {
        if (l.connection_id == k) {
          let district = this.findDataInArray(this.state.dataDistricts, 'id', l.district_id)
          let city = this.findDataInArray(this.state.dataCities, 'id', l.settlement_id)
          cities.push(city)
          districts.push(district)
          result.push(l)
        }
      })
    })
    this.setState({
      placemarkArr: conditions.length == 0 ? this.state.optionsHospitals : result,
      optionsDistricts: result.length == 0 ? this.state.dataDistricts : [...new Set(districts)],
      optionsCities: result.length == 0 ? this.state.dataCities : [...new Set(cities)],
      internetConditions: conditions,
      // optionsHospitals: (result.length == 0) ? this.state.optionsHospitals : result
    })
  }

  mapTabContent = data => {
    return this.state[data].map((l, i) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Form.Check
            inline
            onChange={res => this.checkboxFilter(res.target.checked, l, data)}
            type={'checkbox'}
            label={l.label}
            style={{ color: '#88898b', paddingBottom: 5, fontSize: 14 }}
          />
          {data == 'kindInternets' ? <img src={this.findPlacemarkIcon(l.id)} /> : null}
        </div>
      )
    })
  }

  mapKindInternets = () => {
    let allDisabled = this.findDataInArray(this.state.kindInternets, 'checked', true) ? false : true
    return this.state.kindInternets.map((l, i) => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 45,
          }}
        >
          <label
            style={{ color: !l.checked ? '#818284' : 'black', fontSize: 12 }}
            class="container"
          >
            {l.label}
            <input
              onChange={res => this.checkboxFilter(res.target.checked, l, i)}
              checked={l.checked}
              type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
          <div style={{ height: '77%' }}>
            <img
              className={this.checkBoxDisabledLogic(l, allDisabled)}
              src={this.findPlacemarkIcon(l.id)}
              width={25}
              height={25}
            />
          </div>
        </div>
      )
    })
  }

  checkBoxDisabledLogic = (l, allDisabled) => {
    if (allDisabled) {
      return ''
    } else {
      return !l.checked ? 'checkboxDisableIcon' : ''
    }
  }

  handleToggleSidebar = data => {
    this.setState({
      toggleSidebar: data,
    })
  }

  onTouchStart = a => {
    this.setState({
      startClientX: a.changedTouches[0].clientX,
    })
  }

  onTouchLogic = a => {
    let x = a.changedTouches[0].clientX
    let difference = this.state.startClientX - x
    if (difference > 70) {
      this.handleToggleSidebar(false)
    }
  }

  searchStart = data => {
    // this.setState({mapCenter: [62.028103, 129.732663]})
    // if (!data.get('skip') && this.searchRef.getResultsCount()) {
    //     console.log(this.searchRef.showResult(0))
    // }
  }

  sidebar = () => {
    return (
      <ProSidebar
        onTouchMove={a => this.onTouchLogic(a)}
        onTouchStart={a => this.onTouchStart(a)}
        breakPoint="md"
        toggled={this.state.toggleSidebar}
        onToggle={this.handleToggleSidebar}
      >
        <div className="header">
          <img width={150} src={Logo} />
        </div>
        <div
          className="sidebar-scroll"
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingBottom: 20,
            overflow: 'scroll',
            height: document.documentElement.clientHeight - 90,
          }}
        >
          <br />
          <Select
            onChange={(data, type) => {
              this.changeCoordinate(data, type.action, 'valueDistricts')
            }}
            noOptionsMessage={() => 'Не найдено'}
            isClearable
            styles={this.state.selectStyle}
            value={this.state.valueDistricts}
            placeholder="Улусы (районы)"
            options={this.state.optionsDistricts}
          />
          <Select
            onChange={(data, type) => {
              this.changeCoordinate(data, type.action, 'valueCities')
            }}
            noOptionsMessage={() => 'Не найдено'}
            isClearable
            styles={this.state.selectStyle}
            value={this.state.valueCities}
            placeholder="Населенный пункт"
            options={this.state.optionsCities}
          />
          <Select
            onChange={(data, type) => {
              this.changeCoordinate(data, type.action, 'valueTypes')
            }}
            noOptionsMessage={() => 'Не найдено'}
            isClearable
            styles={this.state.selectStyle}
            value={this.state.valueTypes}
            placeholder="Типы медучреждений"
            options={this.state.optionsTypes}
          />
          <Select
            onChange={(data, type) => {
              this.changeCoordinate(data, type.action, 'valueHospitals')
            }}
            noOptionsMessage={() => 'Не найдено'}
            isClearable
            styles={this.state.selectStyle}
            value={this.state.valueHospitals}
            placeholder="Медучреждения"
            options={this.state.optionsHospitals}
          />
          <Col className="sidebar-tab-content mb-3">
            <div style={{ paddingBottom: 10, color: '#04003e' }}>Типы связи</div>
            {this.mapKindInternets()}
          </Col>
          <Button variant="outline-primary" onClick={this.resetFilter}>
            Сбросить фильтр
          </Button>
        </div>
      </ProSidebar>
    )
  }

  onClear = data => {
    console.log('onClear')
  }

  onResultShow = data => {
    console.log('onResultShow')
  }

  onResultSelect = data => {
    console.log('onResultSelect')
  }

  renderPolygon = () => {
    return this.state.territoryList.map((territory, i) => {
      console.log(territory)
      return (
        <Polygon
          key={i}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          geometry={[territory.coordinates]}
          options={{
            fillColor: this.type[territory.type].color,
            strokeColor: this.type[territory.type].color,
            strokeOpacity: 0.7,
            fillOpacity: 0.02,
            strokeWidth: 2,
          }}
          properties={{
            balloonContentHeader: territory.name,
            balloonContentBody: `
                  <div>${territory.organizationName}</div>
                  <div>Тип участка: ${this.type[territory.type].name}</div>
                  <div>Врач: ${territory.doctor}</div>
                  <div>${territory.cabinet}</div>
                `,
            balloonContentFooter: '',
            hintContent: `
                  <div>${territory.organizationName}</div>
                  <div>Тип участка: ${this.type[territory.type].name}</div>
                  <div>Врач: ${territory.doctor}</div>
                  <div>${territory.cabinet}</div>
                `,
          }}
        />
      )
    })
  }

  main = () => {
    this.count = this.count + 1
    console.log('render ' + this.count)
    return (
      <Container fluid className="pl-0 pr-0">
        <div className="custom-row">
          {this.sidebar()}
          <YMaps enterprise query={{ apikey: process.env.REACT_APP_APIKEY }}>
            <div
              style={{
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
              }}
            >
              <Map
                lang="ru-RU"
                instanceRef={ref => (this.mapRef = ref)}
                // Используем коллбэк функцию при загрузке карты
                onLoad={ymaps => this.getRegions(ymaps)}
                // Подключаем модули регионов и ObjectManager
                modules={['borders', 'ObjectManager', 'geoObject.addon.editor']}
                style={{ width: '100%', height: '100%' }}
                state={{
                  center: this.state.mapCenter,
                  zoom: 4,
                  yandexMapDisablePoiInteractivity: false,
                }}
                options={{ minZoom: 4, maxAnimationZoomDifference: 4 }}
              >
                <div
                  style={{
                    display: this.state.toggleSidebar ? 'none' : 'flex',
                  }}
                  className="btn-toggle"
                  onClick={() => this.handleToggleSidebar(true)}
                >
                  <FaBars />
                </div>
                <ZoomControl options={{ float: 'right' }} />
                <SearchControl
                  onClear={this.onClear}
                  onResultShow={this.onResultShow}
                  onResultSelect={this.onResultSelect}
                  options={{
                    provider: 'yandex#map',
                    maxWidth: 660,
                    fitMaxWidth: true,
                    boundedBy: [
                      [54.6414, 101.564],
                      [77.2557, 161.454],
                    ],
                    strictBounds: true,
                  }}
                  instanceRef={ref => (this.searchRef = ref)}
                  // Используем коллбэк функцию при загрузке карты
                  onLoad={ymaps => this.searchStart(ymaps)}
                />
                {this.mapPlacemark()}
                {/*{this.mapPolygon()}*/}
                {this.renderPolygon()}
              </Map>
            </div>
          </YMaps>
        </div>
      </Container>
    )
  }

  render() {
    return this.main()
  }
}

export default Feed

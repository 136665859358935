const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORG_LIST':
      return {
        ...state,
        orgList: action.payload,
        optionsOrg: action.payload,
      }
    case 'SET_ORG_TYPE_LIST':
      return {
        ...state,
        orgTypeList: action.payload,
        optionsOrgType: action.payload,
      }
    case 'SET_DISTRICT_LIST':
      return {
        ...state,
        districtList: action.payload,
        optionsDistrict: action.payload,
      }
    case 'SET_SETTLEMENT_LIST':
      return {
        ...state,
        settlementList: action.payload,
        optionsSettlement: action.payload,
      }
    case 'SET_CONNECTION_LIST':
      return {
        ...state,
        connectionList: action.payload,
      }
    case 'SET_TERRITORY_LIST':
      return {
        ...state,
        territoryList: action.payload,
      }
    case 'CLEAR_DISTRICT':
      return {
        ...state,
        mapList: action.payload,
        selectedDistrict: null,
        selectedSettlement: null,
        selectedOrg: null,
      }
    case 'CLEAR_SETTLEMENT':
      return {
        ...state,
        mapList: action.payload,
        selectedSettlement: null,
        selectedOrg: null,
      }
    case 'CLEAR_ORG_TYPE':
      return {
        ...state,
        mapList: action.payload,
        selectedOrgType: null,
        selectedOrg: null,
      }
    case 'CLEAR_ORG':
      return {
        ...state,
        mapList: action.payload,
        selectedOrg: null,
      }
    case 'CLEAR_FILTERS':
      return {
        ...state,
        mapList: action.payload.mapList,
        connectionList: action.payload.connectionList,
        selectedDistrict: null,
        selectedSettlement: null,
        selectedOrgType: null,
        selectedOrg: null,
      }
    case 'SELECT_DISTRICT':
      return {
        ...state,
        mapList: action.payload.mapList,
        selectedDistrict: action.payload.data,
        selectedSettlement: null,
        selectedOrg: null,
      }
    case 'SELECT_SETTLEMENT':
      return {
        ...state,
        mapList: action.payload.mapList,
        selectedDistrict: action.payload.district,
        selectedSettlement: action.payload.data,
        selectedOrg: null,
      }
    case 'SELECT_ORG_TYPE':
      return {
        ...state,
        mapList: action.payload.mapList,
        selectedOrgType: action.payload.data,
        selectedOrg: null,
      }
    case 'SELECT_ORG':
      return {
        ...state,
        mapList: action.payload.mapList,
        selectedDistrict: action.payload.district,
        selectedSettlement: action.payload.settlement,
        selectedOrgType: action.payload.orgType,
        selectedOrg: action.payload.data,
      }
    case 'TOGGLE_CONNECTION_CHECKED':
      return {
        ...state,
        mapList: action.payload.mapList,
        selectedOrg: null,
      }
    case 'SET_CENTER':
      return {
        ...state,
        center: action.payload,
      }
    case 'SET_ZOOM':
      return {
        ...state,
        zoom: action.payload,
      }
    case 'SET_MAP_LIST':
      return {
        ...state,
        districtList: action.payload.districtList,
        settlementList: action.payload.settlementList,
        orgTypeList: action.payload.orgTypeList,
        orgList: action.payload.orgList,
        connectionList: action.payload.connectionList,
        mapList: action.payload.orgList,
        orgTerritoryList: action.payload.orgTerritoryList,
        addressList: action.payload.addressList,
      }
    case 'SET_SIDEBAR':
      return {
        ...state,
        sidebarState: action.payload,
      }
    case 'CLICK_MAP':
      return {
        ...state,
        sidebarState: action.payload.sidebarState,
        selectedAddress: action.payload.address,
        selectedPlace: action.payload.place,
        selectedCoordinates: action.payload.coordinates,
        clickedTerritory: action.payload.clickedTerritory,
        clickedOrgTerritory: action.payload.clickedOrgTerritory,
        activeTab: action.payload.activeTab,
      }
    default:
      return state
  }
}

export default reducer

import React, { Component } from 'react'
import { Container, Table, Button, Modal, InputGroup, FormControl, Form } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import EditIcon from '../icons/EditIcon'

class OrganizationTypes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          dataField: 'id',
          text: 'ID',
          sort: true,
        },
        {
          dataField: 'name',
          text: 'Типы учреждений',
          sort: true,
        },
        {
          dataField: 'df1',
          isDummyField: true,
          text: '',
          style: { textAlign: 'right' },
          formatter: (cellContent, row) => {
            return (
              <span className="edit-icon" onClick={this.changeTableTypes.bind(this, row)}>
                <EditIcon />
              </span>
            )
          },
        },
      ],

      defaultSorted: [
        {
          dataField: 'id',
          order: 'asc',
        },
      ],

      kindTypes: [],
      modalShow: false,
      modalMode: '',
      changeRowId: '',
      valueName: '',
      errorName: '',
    }
  }

  fetchTypes = () => {
    fetch(`${process.env.REACT_APP_URL}/api/types`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          kindTypes: result,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  fetchAddData = () => {
    fetch(`${process.env.REACT_APP_URL}/api/type/store`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        name: this.state.valueName,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          alert('Новый тип учреждения успешно добавлен')
          this.setState({
            modalShow: false,
            errorName: '',
          })
          this.fetchTypes()
        } else {
          alert('Произошла ошибка')
          let errorName = response.errors.name ? response.errors.name[0] : ''
          this.setState({ errorName })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        console.log(e)
      })
  }

  fetchChangeData = () => {
    fetch(`${process.env.REACT_APP_URL}/api/type/${this.state.changeRowId}/update`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({
        name: this.state.valueName,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'ok') {
          alert('Изменения успешно внесены')
          this.setState({
            modalShow: false,
            errorName: '',
          })
          this.fetchTypes()
        } else {
          alert('Произошла ошибка')
          let errorName = response.errors.name ? response.errors.name[0] : ''
          this.setState({ errorName })
        }
      })
      .catch(e => {
        alert('Произошла ошибка')
        console.log(e)
      })
  }

  deleteTableRow = () => {
    fetch(`${process.env.REACT_APP_URL}/api/type/${this.state.changeRowId}/destroy`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    })
      .then(response => response.json())
      .then(response => {
        alert('Тип учреждения успешно удалён')
        this.setModalShow(false)
        this.fetchTypes()
      })
      .catch(e => {
        alert('Произошла ошибка')
        console.log(e)
      })
  }

  componentDidMount() {
    this.setState({
      kindTypes: this.props.data,
    })
  }

  changeTableTypes = data => {
    this.setModalShow(true)
    this.setState({
      modalMode: 'edit',
      valueName: data.name,
      changeRowId: data.id,
    })
  }

  addTableTypes = () => {
    this.setModalShow(true)
    this.setState({
      modalMode: 'add',
      valueName: '',
      changeRowId: '',
    })
  }

  saveChangeTable = () => {
    if (this.state.modalMode == 'add') {
      this.fetchAddData()
    } else {
      this.fetchChangeData()
    }
  }

  setModalShow = data => {
    this.setState({
      modalShow: data,
    })
  }

  MyVerticallyCenteredModal() {
    return (
      <Modal
        show={this.state.modalShow}
        onHide={() => this.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.modalMode == 'edit' ? 'Редактировать' : 'Добавить'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <FormControl
              value={this.state.valueName}
              placeholder="Тип учреждения"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className={this.state.errorName ? 'is-invalid' : ''}
              onChange={data => this.setState({ valueName: data.target.value })}
            />
            <div className="invalid-feedback">{this.state.errorName}</div>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {this.state.modalMode == 'edit' ? (
            <Button onClick={this.deleteTableRow} variant="danger">
              Удалить
            </Button>
          ) : null}
          <Button onClick={this.saveChangeTable}>Сохранить</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    return (
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Типы учреждений</span>
          <Button
            onClick={this.addTableTypes}
            style={{ width: '140px', marginLeft: '20px' }}
            variant="outline-primary"
          >
            Добавить
          </Button>
        </div>
        <BootstrapTable
          bordered={false}
          headerWrapperClasses="table-header"
          bodyClasses="table-body"
          rowClasses="table-row"
          bootstrap4
          keyField="asd"
          id="admin-table-type-hospital"
          data={this.state.kindTypes}
          columns={this.state.columns}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage: 25,
          })}
          defaultSorted={this.state.defaultSorted}
        />
        {this.MyVerticallyCenteredModal()}
      </div>
    )
  }
}

export default OrganizationTypes

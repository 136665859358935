const CabinetIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 5.00001H24V24.3182H7V5.00001Z" fill="#D6E9F8"/>
      <path d="M15.5 5H24V24.3182H15.5V5Z" fill="#B2D7F3"/>
      <path d="M13.8094 8.74567H12.6861V7.62239H11.3246V8.74567H10.2013V10.1072H11.3246V11.2305H12.6861V10.1072H13.8094V8.74567Z" fill="#51CAB9"/>
      <path d="M10.2731 12.6753H14.5469V14.0368H10.2731V12.6753Z" fill="#C4D2DC"/>
      <path d="M10.2731 15.3908H20.7268V16.7524H10.2731V15.3908Z" fill="#C4D2DC"/>
      <path d="M10.2731 18.1063H20.7268V19.4679H10.2731V18.1063Z" fill="#C4D2DC"/>
      <path d="M16.6422 8.1357H20.5831V11.1927H16.6422V8.1357Z" fill="#EEF4FF"/>
      <path d="M15.5 15.3908H20.7269V16.7524H15.5V15.3908Z" fill="#9FACB9"/>
      <path d="M15.5 18.1063H20.7269V19.4679H15.5V18.1063Z" fill="#9FACB9"/>
      <path d="M15.5 20.8219H20.7269V22.1834H15.5V20.8219Z" fill="#9FACB9"/>
    </svg>
  )
}

export default CabinetIcon
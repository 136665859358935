import React, { useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { AxiosClient, UploaderClient } from '../json-rpc-client'

const ImportAddresses = () => {
  const fileRef = useRef(null)

  const handleFileUpload = e => {
    const file = e.target.files[0]
    if (file) {
      UploaderClient(file)
        .then(res => {
          const csvFilePath = res.data.result
          AxiosClient('map_updateAddresses', { csvFilePath })
            .then(res => {
              console.log(res.data.result)
            })
            .catch(res => {
              console.log(res.error)
            })
        })
        .catch(res => {
          console.log(res.error)
        })
    }

    e.target.value = '' // clear input
  }

  const renderHeaderButton = () => {
    return (
      <>
        <input ref={fileRef} type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileUpload} />
        <Button
          variant="outline-primary"
          style={{ width: '160px', marginLeft: '20px' }}
          onClick={() => fileRef.current.click()}
        >
          <span>Загрузить файл</span>
        </Button>
      </>
    )
  }

  return (
    <>
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Импорт адресов по MOID</span>
          {renderHeaderButton()}
        </div>
        <div className="admin-container">Новая страница</div>
      </div>
    </>
  )
}

export default ImportAddresses

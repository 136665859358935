import { Navbar, Image, Form, FormControl, Button } from 'react-bootstrap'
import Logo from '../../img/logo-on-light.svg'

const logOut = () => {
  fetch(`${process.env.REACT_APP_URL}/api/auth/logout`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(response => response.json())
    .then(result => {
      window.location.pathname = '/login'
    })
    .catch(e => {
      console.log(e)
    })
}

const Header = props => {
  console.log(props)
  return (
    <Navbar style={{ height: 90 }}>
      <Navbar.Brand href="#hospital">
        <img width={150} src={Logo} style={{ marginLeft: '44px' }} />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end" style={{ marginRight: 10 }}>
        <Navbar.Text id="adminHeader" style={{ fontWeight: 'bold', color: 'black' }}>
          {props.email}
        </Navbar.Text>
        <Navbar.Text
          onClick={() => {
            logOut()
          }}
          style={{ paddingLeft: 5, color: 'black', cursor: 'pointer' }}
        >
          (Выйти)
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header

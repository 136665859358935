import React, { createContext, useReducer } from 'react'
import reducer from './reducer'

const initialState = {
  title: 'Интерактивная карта',
  // state sidebar
  sidebarState: 'filter',
  selectedAddress: '',
  selectedPlace: '',
  selectedCoordinates: [],
  clickedTerritory: {
    therapeutic: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
    pediatric: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
  },
  clickedOrgTerritory: {
    org: '',
    found: false,
    cls: '',
  },
  // request state
  districtList: [],
  settlementList: [],
  orgList: [],
  orgTypeList: [],
  connectionList: [],
  territoryList: [],
  addressList: [],
  // selected options
  optionsDistrict: [],
  optionsSettlement: [],
  optionsOrg: [],
  optionsOrgType: [],
  // selected options
  selectedDistrict: null,
  selectedSettlement: null,
  selectedOrg: null,
  selectedOrgType: null,
  // territoryType
  territoryType: {
    therapeutic: { name: 'Терапевтический', color: '#fc3627' },
    pediatric: { name: 'Педиатрический', color: '#0000FF' },
  },
  // map
  mapList: [],
  orgTerritoryList: [],
  center: [62.028103, 129.732663],
  zoom: 5,
}

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState })
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
}

export const Context = createContext(initialState)
export default Store

const BackIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8125 16.5625C12.5742 16.5625 12.332 16.4727 12.1484 16.2891L6.52344 10.6641C6.15625 10.2969 6.15625 9.70312 6.52344 9.33984L12.1484 3.71094C12.5156 3.34375 13.1094 3.34375 13.4727 3.71094C13.8398 4.07812 13.8398 4.67187 13.4727 5.03516L8.51172 9.99609L13.4727 14.957C13.8398 15.3242 13.8398 15.918 13.4727 16.2812C13.293 16.4727 13.0508 16.5625 12.8125 16.5625Z"
        fill="#D4D4D4"
      />
    </svg>
  )
}

export default BackIcon

import React, { Component } from 'react'
import { Row, Col, Spinner, Card, FormControl, InputGroup, Button, Modal } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'

class Feed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      password: '',
      loading: false,
      isAuth: false,
      errorPasswordText: '',
      errorLoginText: '',
    }
  }

  handleInput(key, e) {
    this.setState({ [key]: e.target.value })
  }

  renderButton() {
    const { login, password, loading } = this.state
    return (
      <Button
        variant="outline-primary"
        onClick={() => this.loginUser({ login, password })}
        disabled={loading}
      >
        {loading ? 'Заходим' : 'Войти'}
      </Button>
    )
  }

  renderLoading() {
    return this.state.loading ? <Spinner animation="border" variant="primary" /> : true
  }

  loginUser({ login, password }) {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(`${process.env.REACT_APP_URL}/api/auth/login`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          body: JSON.stringify({ login, password }),
        })
          .then(response => response.json())
          .then(response => {
            console.log(response)
            if (response.status === 'ok') {
              this.setState({
                isAuth: true,
                loading: false,
                errorPasswordText: '',
                errorLoginText: '',
              })
            } else {
              console.log(response.errors)
              let errorLogin, errorPassword
              errorLogin = response.errors.login ? response.errors.login[0] : ''
              errorPassword = response.errors.password ? response.errors.password[0] : ''
              this.setState({
                loading: false,
                errorLoginText: errorLogin,
                errorPasswordText: errorPassword,
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.setState({
              loading: false,
            })
          })
      },
    )
    console.log(login, password)
  }

  // logoutUser() {
  //   fetch(`${process.env.REACT_APP_URL}/api/auth/logout`, {
  //     method: 'DELETE',
  //     credentials: 'include',
  //   })
  //     .then(response => response.json())
  //     .then(response => console.log(response))
  //     .catch(e => console.log(e))
  // }
  //
  // checkAuth() {
  //   fetch(`${process.env.REACT_APP_URL}/api/user`, {
  //     credentials: 'include',
  //   })
  //     .then(response => response.json())
  //     .then(response => console.log(response))
  //     .catch(e => console.log(e))
  // }

  render() {
    const { login, password, loading, isAuth } = this.state
    if (isAuth) return <Navigate to="/admin" />

    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <Card style={{ width: '400px' }}>
          <Card.Body>
            <Card.Title className="mb-3">Авторизация</Card.Title>
            <InputGroup className="mb-3">
              <FormControl
                required
                type="email"
                placeholder="Логин"
                value={login}
                className={this.state.errorLoginText ? 'is-invalid' : ''}
                id="validationServerUsername"
                onChange={e => this.handleInput('login', e)}
              />
              <div className="invalid-feedback">{this.state.errorLoginText}</div>
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl
                required
                type="password"
                placeholder="Пароль"
                id="validationServerUsername11"
                value={password}
                className={this.state.errorPasswordText ? 'is-invalid' : ''}
                onChange={e => this.handleInput('password', e)}
              />
              <div className="invalid-feedback">{this.state.errorPasswordText}</div>
            </InputGroup>
            <div className="d-flex justify-content-between align-items-center">
              {this.renderButton()}
              {this.renderLoading()}
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default Feed

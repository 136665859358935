import React, { useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import XLSX from 'xlsx'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import Client from '../json-rpc-client'
import axios from 'axios'

const OrganizationTerritory = () => {
  const fileRef = useRef(null)
  const [columns, setColumns] = useState([
    {
      dataField: 'mo_id',
      text: 'mo_id',
      sort: true,
    },
    {
      dataField: 'organization_name',
      text: 'organization_name',
      sort: true,
    },
    {
      dataField: 'fias_aoid',
      text: 'fias_aoid',
      sort: true,
    },
    {
      dataField: 'fias_aoguid',
      text: 'fias_aoguid',
      sort: true,
    },
    {
      dataField: 'address',
      text: 'address',
      sort: true,
    },
    {
      dataField: 'latitude',
      text: 'latitude',
      sort: true,
    },
    {
      dataField: 'longitude',
      text: 'longitude',
      sort: true,
    },
  ])
  const [data, setData] = useState([]) // process CSV data

  console.log(columns, data)

  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/)
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)

    const list = []
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/)
      if (headers && row.length === headers.length) {
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
          let d = row[j]
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1)
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1)
          }
          if (headers[j]) {
            obj[headers[j]] = d
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj)
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      dataField: c,
      text: c,
      sort: true,
    }))

    setData(list)
    setColumns(columns)
  }

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      {options.map((option, i) => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={i}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-primary' : 'page-link'}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const handleFileUpload = e => {
    const validateFile = () => {
      const headers = ['mo_id', 'organization_name', 'fias_aoid', 'fias_aoguid']
    }

    const file = e.target.files[0]
    if (file) {
      console.log(file)
      const reader = new FileReader()
      reader.onload = event => {
        console.log('event', event)
        /* Parse data */
        const bstr = event.target.result
        const wb = XLSX.read(bstr, { type: 'binary', codepage: 1251 })
        /* Get first worksheet */
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
        console.log(data)
        processData(data)
      }
      reader.readAsBinaryString(file)
    }
  }

  const onClickFileUpload = () => {
    fileRef.current.click()
  }

  const onClickProcess = () => {
    function stubFetch(item, i, time) {
      return new Promise(resolve => {
        setTimeout(() => {
          axios
            .post(
              'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/address',
              { query: item.fias_aoguid },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: 'Token 602fcd8aeee2a6a321a3047fa0b336eec970ff75 ',
                },
              },
            )
            .then(({ data }) => {
              const { suggestions } = data
              if (suggestions.length > 0 && suggestions[0].data.fias_level === '7') {
                setData(prevState => {
                  console.log('prevState', prevState[i])
                  prevState[i].latitude = suggestions[0].data.geo_lat
                  prevState[i].longitude = suggestions[0].data.geo_lon
                  console.log('afterState', prevState[i])
                  return prevState
                })
              }
            })
          resolve(true)
        }, time)
      })
    }

    async function asyncForEach(arr, callback) {
      for (let i = 0; i < arr.length; i++) await callback(arr[i], i, arr)
    }

    // 1ый вариант использования
    asyncForEach(data, async (item, i) => {
      await stubFetch(item, i, 50)
    }).then(_ => console.log('Finish'))

    // data.forEach(async (item, i) => {
    //   await (time) => {
    //     return new Promise(resolve => {
    //       setTimeout(() => {
    //         console.log(item)
    //         resolve(true)
    //       }, time)
    //     })
    //   }
    // })
  }

  const onClickClear = () => {
    console.log(data)
    setData([])
    setColumns([])
    fileRef.current.value = ''
  }

  const renderHeaderButton = () => {
    return (
      <>
        <input
          ref={fileRef}
          type="file"
          accept=".csv,.xlsx,.xls"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <Button
          variant="outline-primary"
          style={{ width: '160px', marginLeft: '20px' }}
          onClick={onClickFileUpload}
        >
          <span>Выбрать файл</span>
        </Button>
        <Button
          variant="outline-warning"
          style={{ width: '160px', marginLeft: '20px' }}
          onClick={onClickProcess}
        >
          <span>Обработать</span>
        </Button>
        <Button
          variant="danger"
          style={{ width: '160px', marginLeft: '20px' }}
          onClick={onClickClear}
        >
          <span>Очистить</span>
        </Button>
      </>
    )
  }

  const renderTable = () => {
    if (columns.length > 0) {
      return (
        <>
          <BootstrapTable
            bordered={false}
            headerWrapperClasses="table-header"
            bodyClasses="table-body"
            rowClasses="table-row"
            classes="admin-table"
            bootstrap4
            keyField="import"
            data={data}
            columns={columns}
            pagination={paginationFactory({ sizePerPageRenderer })}
          />
        </>
      )
    }
  }

  return (
    <>
      <div className="admin-content">
        <div className="admin-content-header">
          <span>Импорт из файла</span>
          {renderHeaderButton()}
        </div>
        <div className="admin-container">{renderTable()}</div>
      </div>
    </>
  )
}

export default OrganizationTerritory

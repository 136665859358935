import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/App.scss'
import Admin from './components/admin'
import Map from './components/map'
import Login from './components/login'
import Interactive from './components/interactive'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Interactive />} />,
        <Route exact path="/old" element={<Map />} />,
        <Route exact path="/admin" element={<Admin />} />,
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </Router>
  )
}

export default App

import React, { Component } from 'react'
import { Navigate, Link, Switch, Route } from 'react-router-dom'
import { Container, Row, Col, ListGroup, Tabs, Tab, Navbar } from 'react-bootstrap'
import Territories from './Territories'
import Organizations from './Organizations'
import Connections from './Connections'
import OrganizationTypes from './OrganizationTypes'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { FaHeart, FaBars } from 'react-icons/fa'
import Header from './Header'
import OrganizationTerritory from './OrganizationTerritory'
import Import from './Import'
import ImportAddresses from './ImportAddresses'

class Admin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: [
        { name: 'Учреждения', checked: true },
        { name: 'Типы учреждений', checked: false },
        { name: 'Типы связи', checked: false },
        { name: 'Территории', checked: false },
        { name: 'Участки терапевтические', checked: false },
        { name: 'Участки педиатрические', checked: false },
        // { name: 'Импорт адресов', checked: false },
        // { name: 'Импорт из файла', checked: false },
      ],
      contentIndex: 1,
      hospitalsData: [],
      typesData: [],
      intermetsData: [],
      optionsDistricts: [],
      optionsSettlements: [],
      optionsConnections: [],
      optionsTypes: [],
      rendering: false,
      loading: false,
      isAuth: false,
      redirect: false,
      toggleSidebar: false,
      startClientX: 0,
      territoryType: 'therapeutic',
    }
  }

  fetchHospitals = () => {
    fetch(`${process.env.REACT_APP_URL}/api/orgs`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          hospitalsData: result,
          loading: false,
        })
      })
      .catch(e => {
        this.setState({
          loading: false,
        })
        console.log(e)
      })
  }

  fetchInternets = () => {
    fetch(`${process.env.REACT_APP_URL}/api/connections`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          intermetsData: result,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  fetchTypes = () => {
    fetch(`${process.env.REACT_APP_URL}/api/types`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(result => {
        this.setState({
          typesData: result,
        })
      })
      .catch(e => {
        console.log(e)
      })
  }

  fetchOptionsValues = () => {
    fetch(`${process.env.REACT_APP_URL}/api/district/only-select`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(response => this.setState({ optionsDistricts: response }))
      .catch(e => console.log(e))
    fetch(`${process.env.REACT_APP_URL}/api/settlement/only-select`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(response => this.setState({ optionsSettlements: response }))
      .catch(e => console.log(e))
    fetch(`${process.env.REACT_APP_URL}/api/connection/only-select`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(response => this.setState({ optionsConnections: response }))
      .catch(e => console.log(e))
    fetch(`${process.env.REACT_APP_URL}/api/type/only-select`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(response => this.setState({ optionsTypes: response }))
      .catch(e => console.log(e))
  }

  hashChange = (hash = window.location.hash) => {
    switch (hash) {
      case '#organizations':
        this.setState({ contentIndex: 1 })
        this.changeContentSidebar(0)
        break
      case '#organization-types':
        this.setState({ contentIndex: 2 })
        this.changeContentSidebar(1)
        break
      case '#connections':
        this.setState({ contentIndex: 3 })
        this.changeContentSidebar(2)
        break
      case '#territories':
        this.setState({ contentIndex: 4 })
        this.changeContentSidebar(3)
        break
      case '#therapeutic':
        this.setState({ contentIndex: 5, territoryTypeKey: Math.random() })
        this.changeContentSidebar(4)
        break
      case '#pediatric':
        this.setState({ contentIndex: 6, territoryTypeKey: Math.random() })
        this.changeContentSidebar(5)
        break
      case '#import-addresses':
        this.setState({ contentIndex: 7 })
        this.changeContentSidebar(6)
        break
      case '#import':
        this.setState({ contentIndex: 8 })
        this.changeContentSidebar(7)
        break
      default:
        break
    }
  }

  changeContentSidebar = index => {
    this.state.sidebar.forEach((l, i) => {
      if (i == index) {
        l.checked = true
      } else l.checked = false
    })
  }

  checkHash = () => {
    if (window.location.hash) {
      this.hashChange(window.location.hash)
    }
  }

  componentDidMount() {
    document.title = 'Панель администрирования'
    window.addEventListener('hashchange', e => this.hashChange())
    this.checkHash()
    fetch(`${process.env.REACT_APP_URL}/api/user`, {
      method: 'POST',
      credentials: 'include',
      headers: { Accept: 'application/json' },
    })
      .then(response => response.json())
      .then(response => {
        if (response.email) {
          this.setState(
            {
              isAuth: true,
              loading: true,
              rendering: true,
              redirect: false,
              user: response,
            },
            () => {
              this.fetchInternets()
              this.fetchHospitals()
              this.fetchTypes()
              this.fetchOptionsValues()
            },
          )
        } else {
          this.setState({ redirect: true, rendering: false })
        }
      })
      .catch(e => {
        console.log(e)
        this.setState({ redirect: true, rendering: false })
      })
  }

  sidebarFocus = data => {
    this.state.sidebar.forEach(l => {
      if (data.name == l.name) {
        l.checked = true
      } else l.checked = false
    })
  }

  changeContent = (index, l) => {
    this.sidebarFocus(l)
    switch (index) {
      case 1:
        window.location.hash = 'organizations'
        break
      case 2:
        window.location.hash = 'organization-types'
        break
      case 3:
        window.location.hash = 'connections'
        break
      case 4:
        window.location.hash = 'territories'
        break
      case 5:
        window.location.hash = 'therapeutic'
        break
      case 6:
        window.location.hash = 'pediatric'
        break
      case 7:
        window.location.hash = 'import-addresses'
        break
      case 8:
        window.location.hash = 'import'
        break
      default:
        break
    }
  }

  renderContent = () => {
    if (!this.state.loading) {
      switch (this.state.contentIndex) {
        case 1:
          return (
            <Organizations
              data={this.state.hospitalsData}
              optionsDistricts={this.state.optionsDistricts}
              optionsSettlements={this.state.optionsSettlements}
              optionsConnections={this.state.optionsConnections}
              optionsTypes={this.state.optionsTypes}
              updateData={this.fetchHospitals}
            />
          )
        case 2:
          return <OrganizationTypes data={this.state.typesData} updateData={this.fetchTypes} />
        case 3:
          return <Connections data={this.state.intermetsData} updateData={this.fetchInternets} />
        case 4:
          return <OrganizationTerritory />
        case 5:
          return (
            <Territories
              type="therapeutic"
              typeName="терапевтические"
              color="#FD7669"
              key={this.state.territoryTypeKey}
            />
          )
        case 6:
          return (
            <Territories type="pediatric" typeName="педиатрические" color="#0000FF" key={this.state.territoryTypeKey} />
          )
        case 7:
          return <ImportAddresses />
        case 8:
          return <Import />
        default:
          return null
      }
    } else return null
  }

  findDataInArray = (arr, key, id) => {
    let data = arr.find(x => x[key] == id)
    return data
  }

  handleToggleSidebar = data => {
    this.setState({
      toggleSidebar: data,
    })
  }

  onTouchStart = a => {
    this.setState({
      startClientX: a.changedTouches[0].clientX,
    })
  }

  onTouchLogic = a => {
    let x = a.changedTouches[0].clientX
    let difference = this.state.startClientX - x
    if (difference > 70) {
      this.handleToggleSidebar(false)
    }
  }

  renderSidebar = () => {
    return (
      <ProSidebar
        onTouchMove={a => this.onTouchLogic(a)}
        onTouchStart={a => this.onTouchStart(a)}
        breakPoint="md"
        toggled={this.state.toggleSidebar}
        onToggle={this.handleToggleSidebar}
        className="admin-sidebar"
      >
        <div
          className="sidebar-scroll"
          style={{
            marginLeft: '-15px',
            marginRight: '-15px',
            minHeight: '100%',
            backgroundColor: '#fff',
          }}
        >
          <ListGroup variant="flush">
            {this.state.sidebar.map((l, i) => (
              <ListGroup.Item
                key={i}
                className={l.checked ? 'border-0 text-admin-color-focus' : 'border-0 text-admin-color'}
                action
                onClick={this.changeContent.bind(this, i + 1, l)}
              >
                {l.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      </ProSidebar>
    )
  }

  renderMenuButton = () => {
    return (
      <div
        style={{ display: this.state.toggleSidebar ? 'none' : 'flex', top: 25 }}
        className="btn-toggle"
        onClick={() => this.handleToggleSidebar(true)}
      >
        <FaBars />
      </div>
    )
  }

  render() {
    const { redirect, isAuth, rendering } = this.state
    if (redirect && !isAuth) return <Navigate to="/login" />
    if (rendering) {
      return (
        <React.Fragment>
          <Header email={this.state.user.email} />
          <div className="admin">
            {this.renderSidebar()}
            {this.renderContent()}
            {this.renderMenuButton()}
          </div>
        </React.Fragment>
      )
    }

    return <div>Waiting ...</div>
  }
}

export default Admin

const SearchIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77766 1.43251C5.17547 0.498516 6.81886 0 8.5 0V0.785718L8.50005 0C10.7543 0.000143495 12.9162 0.895717 14.5103 2.48974C16.1043 4.08375 16.9999 6.24567 17 8.49995V8.5C17 10.1811 16.5015 11.8245 15.5675 13.2223C14.6335 14.6202 13.306 15.7096 11.7528 16.353C10.1996 16.9963 8.49057 17.1646 6.84173 16.8367C5.1929 16.5087 3.67834 15.6992 2.4896 14.5104C1.30085 13.3217 0.491303 11.8071 0.163329 10.1583C-0.164645 8.50943 0.00368286 6.80036 0.647028 5.24719C1.29037 3.69402 2.37984 2.3665 3.77766 1.43251ZM8.5 1.57144C10.3375 1.57157 12.0998 2.30158 13.3991 3.60091C14.6984 4.90024 15.4284 6.66247 15.4286 8.5M8.49995 1.57144C7.12963 1.57145 5.79008 1.9778 4.6507 2.73911C3.5113 3.50043 2.62325 4.58252 2.09885 5.84855C1.57444 7.11458 1.43723 8.50768 1.70457 9.85169C1.97191 11.1957 2.63179 12.4303 3.60077 13.3992C4.56975 14.3682 5.8043 15.0281 7.14831 15.2954C8.49232 15.5628 9.88542 15.4256 11.1514 14.9012C12.4175 14.3767 13.4996 13.4887 14.2609 12.3493C15.0222 11.2099 15.4286 9.87037 15.4286 8.50005"
        fill="#D1D1D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2315 14.2315C14.5401 13.9228 15.0406 13.9228 15.3492 14.2315L20.7685 19.6508C21.0772 19.9594 21.0772 20.4599 20.7685 20.7685C20.4599 21.0772 19.9594 21.0772 19.6508 20.7685L14.2315 15.3492C13.9228 15.0406 13.9228 14.5401 14.2315 14.2315Z"
        fill="#D1D1D1"
      />
    </svg>
  )
}

export default SearchIcon

const DoctorIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.55409 17.3102L4.54224 21.322V25.7518C7.24417 28.3804 10.9326 30 15 30V17.8962L11.4949 17.3102H8.55409Z"
        fill="#EEF4FF"
      />
      <path
        d="M18.5051 17.3102L15 17.8962V30C19.0673 30 22.7559 28.3804 25.4579 25.7518V21.3221L21.446 17.3102H18.5051V17.3102Z"
        fill="#D6E9F8"
      />
      <path d="M21.2587 23.4402H22.1118V25.9278H21.2587V23.4402Z" fill="#00BAA0" />
      <path d="M19.4506 25.2994H22.7335V26.4543H19.4506V25.2994Z" fill="#B2D7F3" />
      <path
        d="M19.5595 23.677L17.5246 20.9675H15V30C15.4903 30 15.9749 29.9759 16.4532 29.9299L19.5595 23.677Z"
        fill="#B2D7F3"
      />
      <path
        d="M10.4406 23.677L13.5468 29.9299C14.0251 29.9759 14.5097 30 15 30V20.9675H11.8894L10.4406 23.677Z"
        fill="#D6E9F8"
      />
      <path d="M15 22.8045L19.954 20.6057L18.5051 17.3102H15V22.8045Z" fill="#B2D7F3" />
      <path d="M9.86603 10.2561H10.7191V11.748H9.86603V10.2561Z" fill="#F9A58D" />
      <path d="M19.2808 10.2561H20.1339V11.748H19.2808V10.2561Z" fill="#DF9280" />
      <path d="M15 22.8045L10.046 20.6057L11.4949 17.3102H15V22.8045Z" fill="#D6E9F8" />
      <path
        d="M20.0868 8.66964C20.0868 5.86025 17.8094 3.58282 15 3.58282C12.1907 3.58282 9.91321 5.86025 9.91321 8.66964C9.91321 11.479 12.1906 13.7565 15 13.7565C17.8094 13.7565 20.0869 11.479 20.0868 8.66964Z"
        fill="#464646"
      />
      <path
        d="M20.0868 8.66964C20.0868 5.86025 17.8094 3.58282 15 3.58282V13.7565C17.8094 13.7565 20.0869 11.479 20.0868 8.66964Z"
        fill="#2D2D2D"
      />
      <path
        d="M17.9547 17.3102L15 26.321L12.0096 17.3102V14.7979H17.9547V17.3102Z"
        fill="#DF9280"
      />
      <path d="M17.9548 17.3102L15 26.321V14.7979H17.9548V17.3102Z" fill="#D37F6C" />
      <path
        d="M15 13.9574L19.1631 11.7489L19.3974 9.57902L19.3975 7.47316H10.6026V9.57902L10.837 11.7808L15 13.9574Z"
        fill="#F9A58D"
      />
      <path d="M15 13.9574L19.3975 11.7808V7.47316H15V13.9574Z" fill="#DF9280" />
      <path
        d="M12.6139 10.7498C12.8651 10.7498 13.0688 10.5461 13.0688 10.2948C13.0688 10.0435 12.8651 9.83981 12.6139 9.83981C12.3626 9.83981 12.1589 10.0435 12.1589 10.2948C12.1589 10.5461 12.3626 10.7498 12.6139 10.7498Z"
        fill="#2D2D2D"
      />
      <path
        d="M17.3928 10.7498C17.6441 10.7498 17.8478 10.5461 17.8478 10.2948C17.8478 10.0435 17.6441 9.83981 17.3928 9.83981C17.1415 9.83981 16.9378 10.0435 16.9378 10.2948C16.9378 10.5461 17.1415 10.7498 17.3928 10.7498Z"
        fill="#2D2D2D"
      />
      <path
        d="M14.9999 7.1772L14.8328 7.66857L14.9999 8.17447L17.6618 7.439V7.1772H14.9999Z"
        fill="#2D2D2D"
      />
      <path d="M19.476 9.22686V7.18211H17.443V7.44391L19.476 9.22686Z" fill="#2D2D2D" />
      <path d="M10.5456 7.18306V9.39615L15 8.17447V7.18201L10.5456 7.18306Z" fill="#464646" />
      <path
        d="M10.6047 11.9136L11.3957 11.8373L10.6025 9.57903V11.7808C10.6025 11.8252 10.6034 11.8695 10.6047 11.9136Z"
        fill="#D6E9F8"
      />
      <path
        d="M19.3953 11.9136L18.6042 11.8373L19.3974 9.57903V11.7808C19.3974 11.8252 19.3966 11.8695 19.3953 11.9136Z"
        fill="#B2D7F3"
      />
      <path
        d="M19.3975 11.7808V11.7489L15 11.1741L10.6025 11.7489V11.7808C10.6025 14.2094 12.5713 16.1783 15 16.1783C17.4287 16.1783 19.3975 14.2094 19.3975 11.7808Z"
        fill="#EEF4FF"
      />
      <path
        d="M19.3975 11.7808V11.7489L15 11.1741V16.1783C17.4287 16.1783 19.3975 14.2094 19.3975 11.7808Z"
        fill="#D6E9F8"
      />
      <path d="M12.6321 13.6839H15V14.537H12.6321V13.6839Z" fill="#D6E9F8" />
      <path d="M15 13.6839H17.3679V14.537H15V13.6839Z" fill="#B2D7F3" />
      <path d="M12.6321 12.3071H15V13.1602H12.6321V12.3071Z" fill="#D6E9F8" />
      <path d="M15 12.3071H17.3679V13.1602H15V12.3071Z" fill="#B2D7F3" />
      <path d="M13.0879 20.5594L15 26.321L16.8893 20.5594H13.0879Z" fill="#005FE0" />
      <path d="M15 20.5594V26.321L16.8893 20.5594H15Z" fill="#0051BE" />
    </svg>
  )
}

export default DoctorIcon

import React, { useContext, useEffect, useRef, useState } from 'react'
import { YMaps, Map, ZoomControl, Polygon, Placemark, SearchControl } from 'react-yandex-maps'
import _ from 'lodash'
import Client from '../json-rpc-client'
import { ProSidebar } from 'react-pro-sidebar'
import Select from 'react-select'
import Logo from '../../img/logo-on-light.svg'
import { Context } from '../../store/store'
import CheckedIcon from '../icons/CheckedIcon'
import UncheckedIcon from '../icons/UncheckedIcon'
import { Button } from 'react-bootstrap'
import BackIcon from '../icons/BackIcon'
import HospitalIcon from '../icons/HospitalIcon'
import DoctorIcon from '../icons/DoctorIcon'
import Territory2Icon from '../icons/Territory2Icon'
import CabinetIcon from '../icons/CabinetIcon'

const ST = {
  FILTER: 'filter',
  SEARCH: 'search',
}

export default () => {
  const [state, dispatch] = useContext(Context)
  const [ymaps, setYmaps] = useState(null)
  const [myPlacemark, setMyPlacemark] = useState(null)
  const [activeTab, setActiveTab] = useState('therapeutic')
  const [checkedTerritory, setCheckedTerritory] = useState({
    organization: true,
    therapeutic: true,
    pediatric: true,
  })
  let checkedOrgTerritory = checkedTerritory.organization,
    checkedTherapeutic = checkedTerritory.therapeutic,
    checkedPediatric = checkedTerritory.pediatric
  let mapRef
  let searchRef
  let placemarkRef
  let polygonRef = {}
  let orgPolygonRef = {}
  const {
    mapList,
    addressList,
    districtList,
    settlementList,
    orgTypeList,
    orgList,
    connectionList,
    territoryList,
    orgTerritoryList,
    selectedDistrict,
    selectedSettlement,
    selectedOrg,
    selectedOrgType,
    territoryType,
    center,
    zoom,
    sidebarState,
    selectedAddress,
    selectedPlace,
    selectedCoordinates,
    clickedTerritory,
    clickedOrgTerritory,
  } = state

  const optionsOrg = generateOptions(mapList, orgList, 'id')
  const optionsOrgType = generateOptions(mapList, orgTypeList, 'typeId')
  const optionsDistrict = generateOptions(mapList, districtList, 'districtId')
  const optionsSettlement = generateOptions(mapList, settlementList, 'settlementId')
  const selectStyle = {
    option: styles => ({ ...styles, color: '#3f3b80' }),
    control: styles => ({
      ...styles,
      color: '#3f3b80',
      backgroundColor: '#F2F5F8',
      borderWidth: 0,
      cursor: 'pointer',
    }),
    // placeholder: styles => ({ ...styles, color: '#3f3b80' }),
    container: styles => ({
      ...styles,
      color: '#3f3b80',
      marginTop: '15px',
    }),
    clearIndicator: styles => ({ ...styles, color: '#6e6a94' }),
    singleValue: styles => ({ ...styles, color: '#3f3b80' }),
    dropdownIndicator: styles => ({ ...styles, color: '#6e6a94' }),
    input: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#3f3b80',
      }
    },
  }

  // запросы
  useEffect(() => {
    Client('map_getList', [], 0).then(res => {
      if (res.result) setMapList(res.result)
      else console.log(res.error)
    })
    Client('territory_getList', [], 0).then(res => {
      if (res.result) dispatch({ type: 'SET_TERRITORY_LIST', payload: transformToObjById(res.result) })
      else console.log(res.error)
    })
  }, [])

  const setMapList = result => {
    const districtList = transformToObjById(result.districtList)
    const settlementList = transformToObjById(result.settlementList)
    const orgTypeList = transformToObjById(result.orgTypeList)
    const connectionList = transformToObjById(formatCheckList(result.connectionList))
    const orgList = transformToObjById(result.orgList)
    const addressList = transformToObjById(result.addressList)
    const filteredList = _.filter(orgList, org => org.territory.length > 0)
    const orgTerritoryList = _.map(filteredList, org => {
      return { id: org.id, name: org.name, coordinates: org.territory, color: org.color }
    })
    dispatch({
      type: 'SET_MAP_LIST',
      payload: {
        districtList,
        settlementList,
        orgTypeList,
        orgList,
        connectionList,
        orgTerritoryList,
        addressList,
      },
    })
  }

  const clearDistrict = () => {
    let mapList = filterCheckedList(orgList)

    dispatch({ type: 'CLEAR_DISTRICT', payload: mapList })
  }

  const clearSettlement = () => {
    let mapList = filterCheckedList(orgList)
    if (selectedDistrict) mapList = filterById(mapList, 'districtId', selectedDistrict.value)
    if (selectedOrgType) mapList = filterById(mapList, 'typeId', selectedOrgType.value)

    dispatch({ type: 'CLEAR_SETTLEMENT', payload: mapList })
  }

  const clearOrgType = () => {
    let mapList = filterCheckedList(orgList)
    if (selectedDistrict) mapList = filterById(mapList, 'districtId', selectedDistrict.value)
    if (selectedSettlement) mapList = filterById(mapList, 'settlementId', selectedSettlement.value)

    dispatch({ type: 'CLEAR_ORG_TYPE', payload: mapList })
  }

  const clearOrg = () => {
    let mapList = filterCheckedList(orgList)
    if (selectedDistrict) mapList = filterById(mapList, 'districtId', selectedDistrict.value)
    if (selectedSettlement) mapList = filterById(mapList, 'settlementId', selectedSettlement.value)
    if (selectedOrgType) mapList = filterById(mapList, 'typeId', selectedOrgType.value)
    dispatch({ type: 'CLEAR_ORG', payload: mapList })
  }

  const selectDistrict = data => {
    let mapList = filterById(orgList, 'districtId', data.value)
    mapList = filterCheckedList(mapList)

    dispatch({ type: 'SELECT_DISTRICT', payload: { mapList, data } })
  }
  const selectSettlement = data => {
    const district = _.find(optionsDistrict, select => select.value === settlementList[data.value].districtId)
    let mapList = filterById(orgList, 'settlementId', data.value)
    if (selectedOrgType) mapList = filterById(mapList, 'typeId', selectedOrgType.value)
    mapList = filterCheckedList(mapList)

    dispatch({ type: 'SELECT_SETTLEMENT', payload: { mapList, district, data } })
  }

  const selectOrgType = data => {
    let mapList = filterById(orgList, 'typeId', data.value)
    if (selectedDistrict) mapList = filterById(mapList, 'districtId', selectedDistrict.value)
    if (selectedSettlement) mapList = filterById(mapList, 'settlementId', selectedSettlement.value)
    mapList = filterCheckedList(mapList)

    dispatch({ type: 'SELECT_ORG_TYPE', payload: { mapList, data } })
  }

  const selectOrg = data => {
    const org = orgList[data.value]
    const mapList = [org]
    const district = _.find(optionsDistrict, select => select.value === org.districtId)
    const settlement = _.find(optionsSettlement, select => select.value === org.settlementId)
    const orgType = _.find(optionsOrgType, select => select.value === org.typeId)
    dispatch({ type: 'SELECT_ORG', payload: { mapList, district, settlement, orgType, data } })
  }

  const toggleConnectionCheckbox = (id, count) => {
    if (count) {
      connectionList[id].checked = !connectionList[id].checked

      let mapList = filterCheckedList(orgList)
      if (selectedDistrict) mapList = filterById(mapList, 'districtId', selectedDistrict.value)
      if (selectedSettlement) mapList = filterById(mapList, 'settlementId', selectedSettlement.value)
      if (selectedOrgType) mapList = filterById(mapList, 'typeId', selectedOrgType.value)

      dispatch({ type: 'TOGGLE_CONNECTION_CHECKED', payload: { mapList, connectionList } })
    }
  }

  const filterCheckedList = mapList => {
    const checkedList = filterById(connectionList, 'checked', true)
    if (checkedList.length > 0) {
      mapList = filterByArray(mapList, 'connectionId', uniqueById(checkedList, 'id'))
    }
    return mapList
  }

  const onChangeSelect = (data, type) => {
    const setCenter = list => {
      const center = list[data.value].center
      const zoom = list[data.value].zoom
      if (center && zoom) {
        mapRef.setCenter(center, zoom)
      }
    }

    if (type.action !== 'clear') {
      // select action
      switch (type.name) {
        case 'district':
          setCenter(districtList)
          selectDistrict(data)
          break
        case 'settlement':
          setCenter(settlementList)
          selectSettlement(data)
          break
        case 'organizationType':
          selectOrgType(data)
          break
        case 'organization':
          selectOrg(data)
          setCenter(orgList)
          break
        default:
          break
      }
      // set map center
    } else {
      // select clear
      switch (type.name) {
        case 'district':
          clearDistrict()
          break
        case 'settlement':
          clearSettlement()
          break
        case 'organizationType':
          clearOrgType()
          break
        case 'organization':
          clearOrg()
          break
        default:
          break
      }
    }

    // close balloon
    if (mapRef.balloon.isOpen()) {
      mapRef.balloon.close()
    }
  }

  const RenderConnectionLegend = () => {
    const getCount = id => {
      let countList = orgList
      if (selectedDistrict) countList = filterById(countList, 'districtId', selectedDistrict.value)
      if (selectedSettlement) countList = filterById(countList, 'settlementId', selectedSettlement.value)
      if (selectedOrgType) countList = filterById(countList, 'typeId', selectedOrgType.value)
      if (selectedOrg) countList = filterById(countList, 'id', selectedOrg.value)
      return _.filter(countList, org => org.connectionId === id).length
    }

    return (
      <div className="connection-legend">
        <div className="title">Типы связи</div>
        {_.map(connectionList, (connection, i) => {
          const count = getCount(connection.id)
          const checked = connection.checked && count
          return (
            <div
              key={i}
              title={connection.description}
              className={`element ${checked ? 'checked' : ''}`}
              onClick={() => toggleConnectionCheckbox(connection.id, count)}
            >
              <div className="checkbox-container">
                <div className="checkbox">{checked ? <CheckedIcon /> : <UncheckedIcon />}</div>
                <div className="name">
                  {connection.name}
                  <span className="count">{count}</span>
                </div>
              </div>
              <div className="icon">
                <img
                  src={process.env.REACT_APP_URL + connection.iconUrl}
                  width={25}
                  height={25}
                  alt={connection.name}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const ActivePlacemark = () => {
    if (selectedAddress) {
      return (
        <Placemark
          instanceRef={ref => (placemarkRef = ref)}
          geometry={selectedCoordinates}
          properties={{
            iconCaption: selectedAddress,
          }}
          options={{
            preset: 'islands#blueIconWithCaption',
          }}
        />
      )
    }
    return true
  }

  const RenderPlacemark = () => {
    const filteredAddressList = filterByArray(addressList, 'id', uniqueById(mapList, 'addressId'))
    return _.map(filteredAddressList, (address, id) => {
      const orgs = filterById(mapList, 'addressId', address.id)
      const iconUrl = process.env.REACT_APP_URL + connectionList[orgs[0].connectionId].iconUrl
      let body = ''
      orgs.forEach(org => {
        let iconUrl = process.env.REACT_APP_URL + connectionList[org.connectionId].iconUrl
        body += `<div><img src="${iconUrl}" width="25" height="25"> ${org.name}</div><div>${org.contacts}</div>`
      })
      return (
        <Placemark
          key={id}
          onClick={onClickPlacemark}
          options={{
            iconLayout: 'default#image',
            iconImageHref: iconUrl,
            iconImageSize: [30, 30],
            iconImageOffset: [-15, -30],
          }}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          properties={{
            balloonContentHeader: address.value,
            balloonContentBody: body,
            balloonContentFooter: '',
            hintContent: declOfNum(orgs.length, 'учреждение', 'учреждения', 'учреждений'),
          }}
          geometry={address.center}
        />
      )
    })
  }

  const RenderTerritoryPolygon = () => {
    let list
    if (checkedTherapeutic && checkedPediatric) {
      list = territoryList
    } else if (checkedTherapeutic) {
      list = _.filter(territoryList, territory => territory.type === 'therapeutic')
    } else if (checkedPediatric) {
      list = _.filter(territoryList, territory => territory.type === 'pediatric')
    } else {
      list = []
    }

    return _.map(list, (territory, i) => {
      return (
        <Polygon
          key={i}
          instanceRef={ref => (polygonRef[territory.id] = ref)}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          geometry={[territory.coordinates]}
          options={{
            fillColor: territoryType[territory.type].color,
            strokeColor: territoryType[territory.type].color,
            strokeOpacity: 0.6,
            fillOpacity: 0.02,
            strokeWidth: 3,
            interactivityModel: 'default#transparent',
          }}
          properties={{
            // balloonContentHeader: territory.name,
            // balloonContentBody: `
            //       <div>${territory.organizationName}</div>
            //       <div>Тип участка: ${territoryType[territory.type].name}</div>
            //       <div>Врач: ${territory.doctor}</div>
            //       <div>${territory.cabinet}</div>
            //     `,
            // balloonContentFooter: '',
            hintContent: `
                  <div>${territory.name}</div>
                  <div>${territory.organizationName}</div>
                  <div>Тип участка: ${territoryType[territory.type].name}</div>
                  <div>Врач: ${territory.doctor}</div>
                  <div>${territory.cabinet}</div>
                `,
          }}
        />
      )
    })
  }

  const RenderOrgPolygon = () => {
    if (!checkedOrgTerritory) return true
    let list
    if (selectedOrg == null) {
      list = orgTerritoryList
    } else {
      list = _.filter(orgTerritoryList, org => org.id === selectedOrg.value)
    }

    return _.map(list, (territory, i) => {
      return (
        <Polygon
          key={i}
          instanceRef={ref => (orgPolygonRef[territory.id] = ref)}
          modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
          geometry={territory.coordinates}
          options={{
            fillColor: territory.color,
            strokeColor: territory.color,
            strokeOpacity: 0,
            fillOpacity: 0.02,
            strokeWidth: 3,
            interactivityModel: 'default#transparent',
          }}
          // properties={{
          //   // balloonContentHeader: territory.name,
          //   // balloonContentBody: `
          //   //       <div>${territory.organizationName}</div>
          //   //       <div>Тип участка: ${territoryType[territory.type].name}</div>
          //   //       <div>Врач: ${territory.doctor}</div>
          //   //       <div>${territory.cabinet}</div>
          //   //     `,
          //   // balloonContentFooter: '',
          //   hintContent: `
          //         <div>Территория учреждения:</div>
          //         <div>${territory.name}</div>
          //       `,
          // }}
        />
      )
    })
  }

  const RenderTerritoryLegend = () => {
    const countOrgTerritory = orgTerritoryList.length
    const countTherapeutic = _.filter(territoryList, terr => terr.type === 'therapeutic').length
    const countPediatric = _.filter(territoryList, terr => terr.type === 'pediatric').length

    const toggleOrgTerritory = () => {
      setCheckedTerritory(prevState => ({ ...prevState, organization: !prevState.organization }))
    }
    const toggleTherapeuticTerritory = () => {
      setCheckedTerritory(prevState => ({ ...prevState, therapeutic: !prevState.therapeutic }))
    }
    const togglePediatricTerritory = () => {
      setCheckedTerritory(prevState => ({ ...prevState, pediatric: !prevState.pediatric }))
    }

    return (
      <div className="connection-legend">
        <div className="title">Типы территорий</div>
        <div className={`element ${checkedOrgTerritory ? 'checked' : ''}`} onClick={toggleOrgTerritory}>
          <div className="checkbox-container">
            <div className="checkbox">{checkedOrgTerritory ? <CheckedIcon /> : <UncheckedIcon />}</div>
            <div className="name">
              Территории организаций
              <span className="count">{countOrgTerritory}</span>
            </div>
          </div>
          <div className="icon" style={{ width: '25px', height: '25px' }}>
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '8px',
                backgroundColor: '#39D8D8',
              }}
            />
          </div>
        </div>
        <div className={`element ${checkedTherapeutic ? 'checked' : ''}`} onClick={toggleTherapeuticTerritory}>
          <div className="checkbox-container">
            <div className="checkbox">{checkedTherapeutic ? <CheckedIcon /> : <UncheckedIcon />}</div>
            <div className="name">
              Участки терапевтические
              <span className="count">{countTherapeutic}</span>
            </div>
          </div>
          <div className="icon" style={{ width: '25px', height: '25px' }}>
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '8px',
                backgroundColor: '#fc3627',
              }}
            />
          </div>
        </div>
        <div className={`element ${checkedPediatric ? 'checked' : ''}`} onClick={togglePediatricTerritory}>
          <div className="checkbox-container">
            <div className="checkbox">{checkedPediatric ? <CheckedIcon /> : <UncheckedIcon />}</div>
            <div className="name">
              Участки педиатрические
              <span className="count">{countPediatric}</span>
            </div>
          </div>
          <div className="icon" style={{ width: '25px', height: '25px' }}>
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '8px',
                backgroundColor: '#0000FF',
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const RenderSidebar = () => {
    const sidebarHeader = () => (
      <div className="header">
        <img width={180} src={Logo} alt="" />
      </div>
    )

    switch (sidebarState) {
      case ST.FILTER:
        return (
          <>
            {sidebarHeader()}
            <div className="sidebar-content">
              <Select
                name="district"
                onChange={onChangeSelect}
                noOptionsMessage={() => 'Не найдено'}
                isClearable
                styles={selectStyle}
                value={selectedDistrict}
                placeholder="Улусы (районы)"
                options={optionsDistrict}
                components={{ IndicatorSeparator: () => null }}
              />
              <Select
                name="settlement"
                onChange={onChangeSelect}
                noOptionsMessage={() => 'Не найдено'}
                isClearable
                styles={selectStyle}
                value={selectedSettlement}
                placeholder="Населенный пункт"
                options={optionsSettlement}
                components={{ IndicatorSeparator: () => null }}
              />
              <Select
                name="organizationType"
                onChange={onChangeSelect}
                noOptionsMessage={() => 'Не найдено'}
                isClearable
                styles={selectStyle}
                value={selectedOrgType}
                placeholder="Типы медучреждений"
                options={optionsOrgType}
                components={{ IndicatorSeparator: () => null }}
              />
              <Select
                name="organization"
                onChange={onChangeSelect}
                noOptionsMessage={() => 'Не найдено'}
                isClearable
                styles={selectStyle}
                value={selectedOrg}
                placeholder="Медучреждения"
                options={optionsOrg}
                components={{ IndicatorSeparator: () => null }}
              />
              {/*<RenderConnectionLegend />*/}
              {/*<RenderTerritoryLegend />*/}
              <RenderClearFilterButton />
            </div>
          </>
        )
      case ST.SEARCH:
        const content = () => {
          if (clickedTerritory.therapeutic.found || clickedTerritory.pediatric.found) {
            return (
              <div className="content">
                <div className="description territory-name">
                  <div className="icon">
                    <Territory2Icon />
                  </div>
                  <span>{clickedTerritory[activeTab].name}</span>
                </div>
                <div className="description territory-organization">
                  <div className="icon">
                    <HospitalIcon />
                  </div>
                  <span>{clickedTerritory[activeTab].org}</span>
                </div>
                <div className="description territory-doctor">
                  <div className="icon">
                    <DoctorIcon />
                  </div>
                  <span>{clickedTerritory[activeTab].doctor}</span>
                </div>
                <div className="description territory-cabinet">
                  <div className="icon">
                    <CabinetIcon />
                  </div>
                  <span>{clickedTerritory[activeTab].cabinet}</span>
                </div>
              </div>
            )
          }
          return true
        }

        const orgContent = () => {
          if (clickedOrgTerritory.found) {
            return (
              <div className="content">
                <div className="description territory-organization">
                  <div className="icon">
                    <HospitalIcon />
                  </div>
                  <span>{clickedOrgTerritory.org}</span>
                </div>
              </div>
            )
          }
          return true
        }

        const therapeuticTab = () => {
          if (clickedTerritory.therapeutic.found) {
            return (
              <div
                className={`tab ${activeTab === 'therapeutic' ? 'active' : ''}`}
                onClick={() => setActiveTab('therapeutic')}
              >
                Ваш терапевт
              </div>
            )
          }
          return true
        }

        const pediatricTab = () => {
          if (clickedTerritory.pediatric.found) {
            return (
              <div
                className={`tab ${activeTab === 'pediatric' ? 'active' : ''}`}
                onClick={() => setActiveTab('pediatric')}
              >
                Ваш педиатр
              </div>
            )
          }
          return true
        }

        const orgTerritoryTab = () => {
          if (clickedOrgTerritory.found) {
            return (
              <div
                className={`tab ${activeTab === 'orgTerritory' ? 'active' : ''}`}
              >
                Территория
              </div>
            )
          }
          return true
        }

        const territoryTypes = () => {
          return (
            <div className="territory-types">
              <div className="tabs">
                {therapeuticTab()}
                {pediatricTab()}
                {orgTerritoryTab()}
              </div>
              {content()}
              {orgContent()}
            </div>
          )
        }

        return (
          <>
            {sidebarHeader()}
            <div className="search-content">
              <div className="back-container">
                <div className="back" onClick={() => dispatch({ type: 'SET_SIDEBAR', payload: ST.FILTER })}>
                  <BackIcon />
                  <span>Назад</span>
                </div>
              </div>
              <div className="territory-info">
                <div className="title">{selectedAddress}</div>
                <div className="index">{selectedPlace}</div>
              </div>
              {territoryTypes()}
            </div>
          </>
        )
    }
  }

  const clearFilters = () => {
    const mapList = orgList
    let list = _.map(connectionList, connection => {
      connection.checked = true
      return connection
    })
    mapRef && mapRef.setCenter(center, zoom)
    list = transformToObjById(list)
    dispatch({ type: 'CLEAR_FILTERS', payload: { mapList, connectionList: list } })
  }

  const RenderClearFilterButton = () => {
    return (
      <Button block variant="outline-primary" className="mt-3" onClick={clearFilters}>
        Очистить фильтр
      </Button>
    )
  }

  const searchStart = ymaps => {
  }

  const onClear = () => {
  }

  const onResultSelect = data => {
    const geoObject = data.originalEvent.target.state._data.results[0].properties.getAll()
    const coordinates = data.originalEvent.target.state._data.results[0].geometry._coordinates
    const address = geoObject.name
    const place = geoObject.description
    const sidebarState = ST.SEARCH
    let clickedTerritory = {
      therapeutic: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
      pediatric: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
    }
    let clickedOrgTerritory = {
      name: '',
      found: false,
    }
    _.forEach(polygonRef, (polygon, id) => {
      if (polygon.geometry.contains(coordinates)) {
        const territoryType = territoryList[id].type
        const organizationId = territoryList[id].organizationId
        clickedTerritory[territoryType].name = territoryList[id].name
        clickedTerritory[territoryType].org = orgList[organizationId].name
        clickedTerritory[territoryType].doctor = territoryList[id].doctor
        clickedTerritory[territoryType].cabinet = territoryList[id].cabinet
        clickedTerritory[territoryType].found = true
      }
    })

    _.forEach(orgPolygonRef, (polygon, id) => {
      if (polygon.geometry.contains(coordinates)) {
        const organizationId = orgList[id].id
        clickedOrgTerritory.org = orgList[organizationId].name
        clickedOrgTerritory.found = true
      }
    })

    if (clickedTerritory.therapeutic.found && clickedTerritory.pediatric.found) {
      clickedTerritory.therapeutic.cls = 'active'
      setActiveTab('therapeutic')
    } else if (clickedTerritory.therapeutic.found) {
      clickedTerritory.therapeutic.cls = 'active'
      setActiveTab('therapeutic')
    } else if (clickedTerritory.pediatric.found) {
      clickedTerritory.pediatric.cls = 'active'
      setActiveTab('pediatric')
    }

    dispatch({
      type: 'CLICK_MAP',
      payload: { sidebarState, address, place, coordinates, clickedTerritory, clickedOrgTerritory },
    })
  }

  const onResultShow = () => {
  }

  const onClickMap = e => {
    const coordinates = e.get('coords')
    ymaps.geocode(coordinates).then(res => {
      const firstGeoObject = res.geoObjects.get(0)
      const geoObject = res.geoObjects.get(0).properties.getAll()
      const address = geoObject.name
      // const postalCode = geoObject.metaDataProperty.GeocoderMetaData.Address.postal_code
      const place = geoObject.description
      const sidebarState = ST.SEARCH

      let clickedTerritory = {
        therapeutic: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
        pediatric: { name: '', org: '', doctor: '', cabinet: '', found: false, cls: '' },
      }
      let clickedOrgTerritory = {
        name: '',
        found: false,
      }
      _.forEach(polygonRef, (polygon, id) => {
        if (polygon.geometry.contains(coordinates)) {
          const territoryType = territoryList[id].type
          const organizationId = territoryList[id].organizationId
          clickedTerritory[territoryType].name = territoryList[id].name
          clickedTerritory[territoryType].org = orgList[organizationId].name
          clickedTerritory[territoryType].doctor = territoryList[id].doctor
          clickedTerritory[territoryType].cabinet = territoryList[id].cabinet
          clickedTerritory[territoryType].found = true
        }
      })

      _.forEach(orgPolygonRef, (polygon, id) => {
        if (polygon.geometry.contains(coordinates)) {
          const organizationId = orgList[id].id
          clickedOrgTerritory.org = orgList[organizationId].name
          clickedOrgTerritory.found = true
        }
      })


      if (clickedTerritory.therapeutic.found && clickedTerritory.pediatric.found) {
        clickedTerritory.therapeutic.cls = 'active'
        setActiveTab('therapeutic')
      } else if (clickedTerritory.therapeutic.found) {
        clickedTerritory.therapeutic.cls = 'active'
        setActiveTab('therapeutic')
      } else if (clickedTerritory.pediatric.found) {
        clickedTerritory.pediatric.cls = 'active'
        setActiveTab('pediatric')
      }

      dispatch({
        type: 'CLICK_MAP',
        payload: { sidebarState, address, place, coordinates, clickedTerritory, clickedOrgTerritory },
      })

      // if (!myPlacemark) {
      //   myPlacemark.geometry.setCoordinates(coordinates)
      // } else {
      //   const myPlacemark = ymaps.Placemark(
      //     coordinates,
      //     { iconCaption: 'поиск...' },
      //     { preset: 'islands#blueDotIconWithCaption', draggable: true },
      //   )
      //   mapRef.geoObjects.add(myPlacemark)
      //   myPlacemark.events.add('dragend', () => {
      //     const newCoordinates = myPlacemark.geometry.getCoordinates()
      //
      //     myPlacemark.properties.set({
      //       // Формируем строку с данными об объекте.
      //       iconCaption: [
      //         // Название населенного пункта или вышестоящее административно-территориальное образование.
      //         firstGeoObject.getLocalities().length
      //           ? firstGeoObject.getLocalities()
      //           : firstGeoObject.getAdministrativeAreas(),
      //         // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
      //         firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
      //       ]
      //         .filter(Boolean)
      //         .join(', '),
      //       // В качестве контента балуна задаем строку с адресом объекта.
      //       balloonContent: firstGeoObject.getAddressLine(),
      //     })
      //   })
      //
      //   setMyPlacemark(myPlacemark)
      // }
    })
  }

  const onLoadMap = ymaps => {
    mapRef.cursors.push('arrow')
    setYmaps(ymaps)
  }

  return (
    <div className="intmap-container">
      <ProSidebar
        // onTouchMove={a => this.onTouchLogic(a)}
        // onTouchStart={a => this.onTouchStart(a)}
        breakPoint="md"
        // toggled={this.state.toggleSidebar}
        // onToggle={this.handleToggleSidebar}
      >
        <RenderSidebar />
      </ProSidebar>
      <YMaps enterprise query={{ apikey: process.env.REACT_APP_APIKEY }}>
        <Map
          lang="ru-RU"
          className="interactive-map"
          state={{ center, zoom }}
          options={{ minZoom: 10, maxAnimationZoomDifference: 4 }}
          instanceRef={ref => (mapRef = ref)}
          onClick={onClickMap}
          onLoad={onLoadMap}
          modules={['geocode', 'placemark']}
        >
          <SearchControl
            onClear={onClear}
            onResultShow={onResultShow}
            onResultSelect={onResultSelect}
            options={{
              provider: 'yandex#map',
              maxWidth: 660,
              fitMaxWidth: true,
              boundedBy: [
                [54.6414, 101.564],
                [77.2557, 161.454],
              ],
              strictBounds: true,
              noPlacemark: true,
            }}
            instanceRef={ref => (searchRef = ref)}
            // Используем коллбэк функцию при загрузке карты
            onLoad={ymaps => searchStart(ymaps)}
          />
          <ZoomControl />
          <RenderPlacemark />
          <RenderTerritoryPolygon />
          <RenderOrgPolygon />
          <ActivePlacemark />
        </Map>
      </YMaps>
    </div>
  )
}

const onClickPlacemark = () => {
}

const formatForSelect = data => {
  const formatted = _.map(data, el => {
    return { label: el.name, value: el.id }
  })
  return _.sortBy(formatted, [
    o => {
      return o.label
    },
  ])
}

const formatCheckList = data => {
  return _.map(data, element => {
    element.checked = true
    return element
  })
}

const findById = (data, id) => _.find(data, elem => elem.id === id)

const filterById = (data, filterId, id) => _.filter(data, elem => elem[filterId] === id)

const filterByArray = (data, filterId, idList) => _.filter(data, elem => idList.includes(elem[filterId]))

const uniqueById = (data, filterId) => {
  let filterIdList = _.map(data, elem => elem[filterId])
  return _.uniq(filterIdList)
}

const transformToObjById = data => {
  return data.reduce((acc, elem) => {
    return { ...acc, [elem.id]: { ...elem } }
  }, {})
}

const generateOptions = (list, data, filterId) => {
  const uniqueIdList = uniqueById(list, filterId)
  const filteredData = filterByArray(data, 'id', uniqueIdList)
  return formatForSelect(filteredData)
}

const declOfNum = (n, text1, text2, text3) => {
  n = Math.abs(n) % 100
  let n1 = n % 10
  if (n > 10 && n < 20) return `${n} ${text3}`
  if (n1 > 1 && n1 < 5) return `${n} ${text2}`
  if (n1 === 1) return `${n} ${text1}`
  return `${n} ${text3}`
}
